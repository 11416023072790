import Autocomplete from "@mui/material/Autocomplete";
import { TextField, styled } from "@mui/material";
import { forwardRef } from "react";
import * as React from "react";
import { useSelector } from "react-redux";

export const SelectSmsTypes = forwardRef((props, ref) => {
  const types = useSelector((state) => state.transferBalance.types);

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    borderBottom: "1px solid #e0e0e0",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <Autocomplete
      ref={ref}
      {...props}
      options={types || []}
      getOptionLabel={(option) => {
        return option.key;
      }}
      renderInput={(params) => (
        <TextField
          required={props.required}
          error={props.error}
          helperText={props.helperText}
          {...params}
          label="نوع الرسالة"
          size="small"
        />
      )}
      renderGroup={(params, key) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
});
