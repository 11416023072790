import { Grid } from "@mui/material";
import React from "react";
import JMonthlyTargetHeader from "./home/header";
import JMonthlyTargetTable from "./home/table";

const JMonthlyTarget = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JMonthlyTargetHeader />
      </Grid>
      <Grid item xs={12}>
        <JMonthlyTargetTable />
      </Grid>
    </Grid>
  );
};

export default JMonthlyTarget;
