import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Chip,
  Link,
  useMediaQuery,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { User } from "../../../../../../app/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import TransactionForm from "../../form";
import { PointOfSales } from "../../../../../../app/slices/posSlice";
import PosForm from "../../../../../POS/form";
import { ExportButton } from "../../../../../../components/ExportExcel/ExportButton";
import ConfirmationDialog from "../../../../../../helpers/ConfirmationDialog";
import {
  JClearance,
  setCashFromPosDialog,
  setCashFromRepDialog,
  setJTransactionDialog,
  setSearchBody,
} from "../../../../../../app/slices/jomla/clearanceSlice";
import { BiInfoCircle } from "react-icons/bi";
import { SelectJTransaction } from "../../../../../../components/Selectors/jomla/SelectTransactionTypes";
import { useTheme } from "@emotion/react";

const JTransactionTable = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { user_id } = useParams();
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    search: "",
    order_id: "",
    user_type: null,
    date: "",
    j_type_id: null,
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
    skip: 0,
    number: "",
    order_number: "",
  });

  const _JTransactions = useSelector((state) => state.jClearance.jClearances);
  const searchBody = useSelector((state) => state.jClearance.searchBody);
  const _JPosTransactions = useSelector(
    (state) => state.jClearance.jPosTransaction
  );
  const user = useSelector((state) => state.user.user.form);
  const _Wallet = useSelector((state) => state.wallet);
  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);
  const _PointsOfsales = useSelector((state) => state.pointOfsales);
  const _JTransactionTypes = useSelector(
    (state) => state.jTransaction.jTransactionTypes.data
  );

  const [reload, setReload] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});

  const columns = [
    {
      field: "number",
      headerName: "رقم المناقلة",
      flex: 1,
    },
    {
      field: "pos_user_name",
      headerName: "نقطة البيع",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "القيمة (IQD)",
      flex: 1,
      renderCell: (params) => params.row.amount?.toLocaleString(),
    },
    {
      field: "created_by_name",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "rep_name",
      headerName: "المندوب",
      flex: 1,
    },
    {
      field: "rep_j_cash",
      headerName: "الرصيد السابق (IQD)",
      flex: 1,
      renderCell: (params) => params.row.rep_j_cash?.toLocaleString(),
    },
    {
      field: "rep_new_j_cash",
      headerName: "الرصيد الحالي (IQD)",
      flex: 1,
      renderCell: (params) => params.row.rep_new_j_cash?.toLocaleString(),
    },
    {
      field: "is_received",
      headerName: "نوع العملية (IQD)",
      flex: 1,
      renderCell: (params) => (params.row.is_received ? "استلام" : "تسديد"),
    },
    {
      field: "created_at",
      headerName: "تاريخ العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Typography>
              {dayjs(params.row.created_at).format("YYYY-MM-DD ")}
              {params.row.note && (
                <Tooltip title={params.row.note}>
                  <IconButton size="small">
                    <BiInfoCircle />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            <Typography>
              {dayjs(params.row.created_at).format("hh:mm: a")}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  const posColumns = [
    {
      field: "number",
      headerName: "رقم المناقلة",
      flex: 1,
    },
    {
      field: "j_order_id",
      headerName: "رقم الطلب",
      flex: 1,
      renderCell: (params) => (
        <Link
          href={`/app/wholesale/order/${params.row.j_order_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.row?.j_order_number}
        </Link>
      ),
    },
    {
      field: "amount",
      headerName: "القيمة (IQD)",
      flex: 1,
      renderCell: (params) => params.row.amount?.toLocaleString(),
    },
    {
      field: "created_by",
      headerName: "بواسطة",
      flex: 1,
    },
    // {
    //   field: "main_j_balance",
    //   headerName: "رصيد الخزنة (IQD)",
    //   flex: 1,
    //   renderCell: (params) => params.row.main_j_balance?.toLocaleString(),
    // },
    // {
    //   field: "new_main_j_balance",
    //   headerName: "رصيد الخزنة الجديد (IQD)",
    //   flex: 1,
    //   renderCell: (params) => params.row.new_main_j_balance?.toLocaleString(),
    // },
    {
      field: "pos_j_balance",
      headerName: "الديون السابقة (IQD)",
      flex: 1,
      renderCell: (params) => params.row.pos_j_balance?.toLocaleString(),
    },
    {
      field: "new_pos_j_balance",
      headerName: "الديون الحالية (IQD)",
      flex: 1,
      renderCell: (params) => params.row.new_pos_j_balance?.toLocaleString(),
    },
    {
      field: "j_type_name",
      headerName: "نوع العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Typography>{params.row.j_type_name}</Typography>
            {params.row.note && (
              <Tooltip title={params.row.note}>
                <IconButton size="small">
                  <BiInfoCircle />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      field: "created_at",
      headerName: "تاريخ العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Typography>
              {dayjs(params.row.created_at).format("YYYY-MM-DD ")}
            </Typography>
            <Typography>
              {dayjs(params.row.created_at).format("hh:mm: a")}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (user.role === "pos") {
        dispatch(JClearance.getAllPosTransactions(searchBody));
      } else {
        dispatch(JClearance.getAll(searchBody));
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, searchBody, user.role]);

  useEffect(() => {
    if (user.role === "pos") {
      dispatch(
        setSearchBody({
          user_id: user_id,
          skip: currentPage * pageSize,
          take: pageSize,
          j_type_id: search.j_type_id ? JSON.stringify(search.j_type_id) : "",
          start_date: search.startDate,
          end_date: search.endDate,
          number: search.number,
          order_number: search.order_number,
        })
      );
    } else {
      dispatch(
        setSearchBody({
          rep_id: user_id,
          skip: currentPage * pageSize,
          take: pageSize,
          type: search.type ? JSON.stringify(search.type) : "",
          start_date: search.startDate,
          end_date: search.endDate,
        })
      );
    }
  }, [currentPage, dispatch, pageSize, search, user.role, user_id]);

  useEffect(() => {
    dispatch(User.getByIdData(user_id));
  }, [user_id, _Wallet.reload, dispatch, reload]);

  const getUserType = (_user) => {
    if (_user.is_representative) return "مندوب";
    else if (_user.is_j_rep) return "مندوب جملة";
    else if (_user.role === "pos")
      return `نقطة بيع : ${_user.pos_number ? `(${_user.pos_number})` : ""}`;
    else return "غير معرف";
  };

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_JTransactions.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const customToolbar = () => (
    <GridToolbarContainer>
      <ExportButton fileName="تقرير كشف الحساب | نظام دجلة" />

      {/* <GridToolbarExport
        csvOptions={{
          fileName: "تقرير كشف الحساب | نظام دجلة",
          utf8WithBom: true,
        }}
      /> */}
    </GridToolbarContainer>
  );

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(
      User.WalletActivation({
        user_id: dialogActiveData.id,
        is_active: !dialogActiveData.wallet?.is_active,
      })
    )
      .then((x) => {
        setReload(!reload);
      })
      .catch((e) => {
        setReload(!reload);
      });
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <TransactionForm />
          {_PointsOfsales.pos.dialog ? <PosForm /> : null}
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={
              dialogActiveData.wallet?.is_active
                ? "هل انت متاكد من الغاء تفعيل المحفظة: "
                : "هل انت متاكد من تفعيل المحفظة :"
            }
            name={dialogActiveData.user_name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid container spacing={2} sx={{ mx: 1 }}>
            <Grid item xs={12} md={2}>
              <Stack spacing={2}>
                <Typography variant="h4">
                  الاسم : {user ? user.name : ""}
                </Typography>
                <Typography variant="h4">
                  رقم الهاتف : {user ? user.mobile : ""}
                </Typography>
                <Typography variant="h4">
                  {" "}
                  نوع الحساب : {user ? getUserType(user) : ""}
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography variant="h4"> حالة المحفظة : </Typography>
                  <Chip
                    label={user.wallet?.is_active ? "فعال" : "غير فعال"}
                    color={user.wallet?.is_active ? "success" : "error"}
                    onClick={() => {
                      handleActiveClick(user);
                    }}
                  ></Chip>
                </Stack>
                {!user.is_representative && _Role !== "wholesale" && (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => dispatch(PointOfSales.getById(user.pos_id))}
                  >
                    تعديل
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack spacing={2}>
                {user.role === "pos" ? (
                  <Typography variant="h4" color={"error"}>
                    الديون : {user.wallet?.j_balance?.toLocaleString()} IQD
                  </Typography>
                ) : null}
                {user.is_j_rep ? (
                  <Typography variant="h4" color={"error"}>
                    الكاش : {user.wallet?.j_cash?.toLocaleString()} IQD
                  </Typography>
                ) : null}
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack spacing={1} direction="row" sx={{ direction: "ltr" }}>
                {user.role === "pos" && (
                  <Button
                    variant="contained"
                    onClick={() => dispatch(setCashFromPosDialog())}
                  >
                    تسديد ديون
                  </Button>
                )}
                {user.role === "pos" && _Role === "super_admin" && (
                  <Button
                    variant="contained"
                    onClick={() => dispatch(setJTransactionDialog())}
                  >
                    اضافة حركة مالية
                  </Button>
                )}
                {user.is_j_rep ? (
                  <Button
                    variant="contained"
                    onClick={() => dispatch(setCashFromRepDialog())}
                  >
                    استلام نقد
                  </Button>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
          {user.role === "pos" ? (
            <Grid item xs={12} md={2}>
              <TextField
                size="small"
                value={search.number}
                onChange={(e) => {
                  setSearch({
                    ...search,
                    number: e.target.value,
                  });
                }}
                label="بحث عن رقم المناقلة"
                fullWidth
              />
            </Grid>
          ) : null}
          {user.role === "pos" ? (
            <Grid item xs={12} md={2}>
              <SelectJTransaction
                onChange={(e, newValue) => {
                  setSearch({
                    ...search,
                    j_type_id: newValue ? newValue.id : null,
                  });
                }}
                value={
                  _JTransactionTypes.filter(
                    (x) => x.id === search.j_type_id
                  )[0] ?? null
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.order_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  order_number: e.target.value,
                });
              }}
              label="بحث عن رقم الطلب"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid> */}
          {user.role === "pos" ? (
            <Grid item xs={1}>
              <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
                <Tooltip title="الغاء الفلتر">
                  <IconButton
                    onClick={() => {
                      setSearch({
                        search: "",
                        order_id: "",
                        user_type: null,
                        date: "",
                        j_type_id: null,
                        startDate: dayjs().format("2023-01-01"),
                        endDate: dayjs().format("YYYY-MM-DD"),
                        skip: 0,
                        take: 15,
                        number: "",
                        order_number: "",
                      });
                      setPageSize(15);
                      setCurrentPage(0);
                    }}
                  >
                    <MdOutlineClear />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {user.role === "pos" ? (
              smallScreen ? (
                _JPosTransactions?.data?.length > 0 ? (
                  <Grid item xs={12}>
                    {_JTransactions.loading ? <LinearProgress /> : null}
                    {_JPosTransactions.data.map((row, index) => (
                      <Card key={index} sx={{ marginBottom: 0.5 }}>
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="h4" color="primary">
                                رقم المناقلة: {row.number}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                رقم الطلب:
                                <strong>
                                  <Link
                                    href={`/app/wholesale/order/${row.j_order_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {row.j_order_number}
                                  </Link>
                                </strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                القيمة (IQD):{" "}
                                <strong>{row.amount?.toLocaleString()}</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                بواسطة: <strong>{row.created_by}</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                الديون السابقة (IQD):{" "}
                                <strong>
                                  {row.pos_j_balance?.toLocaleString()}
                                </strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                الديون الحالية (IQD):{" "}
                                <strong>
                                  {row.new_pos_j_balance?.toLocaleString()}
                                </strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack direction="row" spacing={1}>
                                <Typography>
                                  نوع العملية:{" "}
                                  <strong>{row.j_type_name}</strong>
                                </Typography>
                                {row.note && (
                                  <Tooltip title={row.note}>
                                    <IconButton size="small">
                                      <BiInfoCircle />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                تاريخ العملية:{" "}
                                <strong>
                                  {" "}
                                  {dayjs(row.created_at).format(
                                    "(hh:mm a) - YYYY-MM-DD"
                                  )}
                                </strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
                    <Card sx={{ mt: 2, width: "100%" }}>
                      <TablePagination
                        component="div"
                        count={_JPosTransactions.total}
                        rowsPerPage={pageSize}
                        page={currentPage}
                        onPageChange={(e, newPage) => setCurrentPage(newPage)}
                        rowsPerPageOptions={[15, 25, 50, 100]}
                        onRowsPerPageChange={(event) => {
                          const newRowsPerPage = parseInt(
                            event.target.value,
                            10
                          );
                          setPageSize(newRowsPerPage);
                        }}
                      />
                    </Card>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Card>
                      <Typography
                        m={2}
                        variant="h5"
                        color="primary"
                        align="center"
                      >
                        لا توجد بيانات
                      </Typography>
                    </Card>
                  </Grid>
                )
              ) : (
                <Grid item xs={12}>
                  <Box sx={{ height: "45vh", width: "100%" }}>
                    <DataGrid
                      rows={_JPosTransactions.data}
                      columns={posColumns}
                      pageSize={pageSize}
                      page={currentPage}
                      rowCount={_JPosTransactions.total}
                      loading={_JTransactions.loading}
                      rowHeight={65}
                      pagination
                      paginationMode="server"
                      selectionModel={[]}
                      onPageChange={(newPage) => {
                        setCurrentPage(newPage);
                      }}
                      rowsPerPageOptions={[15, 25, 50, 100]}
                      onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                      }}
                      components={{
                        LoadingOverlay: LinearProgress,
                        Footer: CustomFooter,
                        Toolbar: customToolbar,
                      }}
                    />
                  </Box>
                </Grid>
              )
            ) : smallScreen ? (
              _JTransactions?.data?.length > 0 ? (
                <Grid item xs={12}>
                  {_JTransactions.loading ? <LinearProgress /> : null}
                  {_JTransactions.data.map((row, index) => (
                    <Card key={index} sx={{ marginBottom: 0.5 }}>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h4" color="primary">
                              رقم المناقلة: {row.number}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              نقطة البيع:<strong>{row.pos_user_name}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              القيمة (IQD):{" "}
                              <strong>{row.amount?.toLocaleString()}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              بواسطة: <strong>{row.created_by_name}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              المندوب: <strong>{row.rep_name}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              الرصيد السابق (IQD):{" "}
                              <strong>
                                {row.rep_j_cash?.toLocaleString()}
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              الرصيد الحالي (IQD):{" "}
                              <strong>
                                {row.rep_new_j_cash?.toLocaleString()}
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              نوع العملية:{" "}
                              <strong>
                                {row.is_received ? "استلام" : "تسديد"}
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack spacing={1}>
                              <Typography>
                                تاريخ العملية:
                                <strong>
                                  {dayjs(row.created_at).format(
                                    "(hh:mm a) - YYYY-MM-DD"
                                  )}
                                </strong>
                              </Typography>
                            </Stack>
                          </Grid>
                          {row.note && (
                            <Grid item xs={12}>
                              <Typography>
                                الملاحظات: <strong>{row.note}</strong>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
                  <Card sx={{ mt: 2, width: "100%" }}>
                    <TablePagination
                      component="div"
                      count={_JTransactions.total}
                      rowsPerPage={pageSize}
                      page={currentPage}
                      onPageChange={(e, newPage) => setCurrentPage(newPage)}
                      rowsPerPageOptions={[15, 25, 50, 100]}
                      onRowsPerPageChange={(event) => {
                        const newRowsPerPage = parseInt(event.target.value, 10);
                        setPageSize(newRowsPerPage);
                      }}
                    />
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Card>
                    <Typography
                      m={2}
                      variant="h5"
                      color="primary"
                      align="center"
                    >
                      لا توجد بيانات
                    </Typography>
                  </Card>
                </Grid>
              )
            ) : (
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: user.role === "pos" ? "45vh" : "60vh",
                    width: "100%",
                  }}
                >
                  <DataGrid
                    rows={_JTransactions.data}
                    columns={columns}
                    pageSize={pageSize}
                    page={currentPage}
                    rowCount={_JTransactions.total}
                    loading={_JTransactions.loading}
                    rowHeight={65}
                    pagination
                    paginationMode="server"
                    selectionModel={[]}
                    onPageChange={(newPage) => {
                      setCurrentPage(newPage);
                    }}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onPageSizeChange={(newPageSize) => {
                      setPageSize(newPageSize);
                    }}
                    components={{
                      LoadingOverlay: LinearProgress,
                      Footer: CustomFooter,
                      Toolbar: customToolbar,
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JTransactionTable;
