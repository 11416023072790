import { forwardRef } from "react";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setMapDataDialog } from "../../../../app/slices/posSlice";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const customIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PosMapData = (props) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.pointOfsales.mapData);

  const handleDialog = () => {
    dispatch(setMapDataDialog());
  };

  return (
    <Dialog
      open={items.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth="lg"
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          خريطة نقاط البيع
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} height={800} width={800}>
            <MapContainer
              center={[33.325891, 44.47519]}
              zoom={16}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {items.data
                .filter(
                  (location) =>
                    location.latitude !== null && location.longitude !== null
                )
                .map((location, index) => (
                  <Marker
                    key={index}
                    position={[location.latitude, location.longitude]}
                    icon={customIcon}
                  >
                    <Popup>
                      {location.name} - {location.pos_number}
                    </Popup>
                  </Marker>
                ))}
            </MapContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PosMapData;
