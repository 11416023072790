import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import appSlice from "./slices/appSlice";

import notificationSlice from "./slices/notificationSlice";
import userSlice from "./slices/userSlice";
import categorySlice from "./slices/categorySlice";
import subCategories from "./slices/subcategorySlice";
import pointOfsales from "./slices/posSlice";
import groupSlice from "./slices/groupSlice";
import provincesSlice from "./slices/provincesSlice";
import bannerSlice from "./slices/bannerSlice";
import supplierSlice from "./slices/supplierSlice";
import itemsSlice from "./slices/itemsSlice";
import appNotificationSlice from "./slices/appNotificationSlice";
import pinsSlice from "./slices/pinsSlice";
import operationSlice from "./slices/operationSlice";
import walletSlice from "./slices/walletSlice";
import requestPointsSlice from "./slices/request-points";
import ordersSlice from "./slices/orderSlice";
import reportsSlice from "./slices/reportsSlice";
import rolesSlice from "./slices/rolesSlice";
import statisticSlice from "./slices/statisticSlice";
import expenseSlice from "./slices/expenseSlice";
import expenseTypesSlice from "./slices/expenseTypesSlice";
import teamSlice from "./slices/teamSlice";
import productsSlice from "./slices/productsSlice";
import productsSupplierSlice from "./slices/productsSupplierSlice";
import productsOrdersSlice from "./slices/productsOrdersSlice";
import externalRevenueSlice from "./slices/externalRevenueSlice";
import simCard from "./slices/simCardSlice";
import posDiscountSlice from "./slices/posDiscountSlice";
import operationTransferSlice from "./slices/operationTransferSlice";
import transferBalanceSlice from "./slices/transferBalanceSlice";
import ticketsSlice from "./slices/ticketsSlice";
import projectsSlice from "./slices/projectsSlice";
import cardsSlice from "./slices/cardsSlice";
import orderRechargeCardsSlice from "./slices/orderRechargeCardsSlice";
import issuedCardSlice from "./slices/issuedCardSlice";
import investorsSlice from "./slices/investorsSlice";
import epaymentTransactionsSlice from "./slices/epaymentTransactionsSlice";
import jCategorySlice from "./slices/jomla/categorySlice";
import jSubCategorySlice from "./slices/jomla/subcategorySlice";
import JItemsSlice from "./slices/jomla/itemsSlice";
import jSupplier from "./slices/jomla/supplierSlice";
import jInvoiceSlice from "./slices/jomla/invoiceSlice";
import jTransactionSlice from "./slices/jomla/transactionsSlice";
import jOrderSlince from "./slices/jomla/orderSlice";
import JClearanceSlice from "./slices/jomla/clearanceSlice";
import PosExternalOrder from "./slices/pos/externalOrdersSlice";
import jReportsSlice from "./slices/jomla/reportsSlice";
import jSafeSlice from "./slices/jomla/safeSlice";
import nonePosSlice from "./slices/nonePosSlice";
import jPosSlice from "./slices/jomla/posSlice";
import serviceSlice from "./slices/serviceSlice";
import settingsSlice from "./slices/settingsSlice";
import posTypeSlice from "./slices/posType";
import posFieldSlice from "./slices/posField";
import jGroupSlice from "./slices/jomla/jGroupSlice";
import jExpenseSlice from "./slices/jomla/expenseSlice";
import JMonthlyTargetSlice from "./slices/jomla/monthlyTargetSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    notification: notificationSlice,
    user: userSlice,
    categories: categorySlice,
    subCategories: subCategories,
    pointOfsales: pointOfsales,
    group: groupSlice,
    provinces: provincesSlice,
    banner: bannerSlice,
    supplier: supplierSlice,
    items: itemsSlice,
    appNotification: appNotificationSlice,
    pins: pinsSlice,
    operation: operationSlice,
    wallet: walletSlice,
    requestPoints: requestPointsSlice,
    orders: ordersSlice,
    reports: reportsSlice,
    role: rolesSlice,
    statistic: statisticSlice,
    expense: expenseSlice,
    expenseTypes: expenseTypesSlice,
    team: teamSlice,
    product: productsSlice,
    productSupplier: productsSupplierSlice,
    productsOrders: productsOrdersSlice,
    externalRevenue: externalRevenueSlice,
    simCard: simCard,
    posDiscount: posDiscountSlice,
    operationTransfer: operationTransferSlice,
    transferBalance: transferBalanceSlice,
    tickets: ticketsSlice,
    projects: projectsSlice,
    cards: cardsSlice,
    orderRechargeCards: orderRechargeCardsSlice,
    issuedCard: issuedCardSlice,
    investors: investorsSlice,
    epaymentTransactions: epaymentTransactionsSlice,
    nonePos: nonePosSlice,
    service: serviceSlice,
    settings: settingsSlice,
    posType: posTypeSlice,
    posField: posFieldSlice,

    //-----------------------------|| wholeSale ||----------------------------//

    JCategories: jCategorySlice,
    JSubCategories: jSubCategorySlice,
    JItems: JItemsSlice,
    jSupplier: jSupplier,
    jInvoice: jInvoiceSlice,
    jTransaction: jTransactionSlice,
    jOrder: jOrderSlince,
    jClearance: JClearanceSlice,
    jReports: jReportsSlice,
    jSafe: jSafeSlice,
    jPos: jPosSlice,
    jGroup: jGroupSlice,
    jExpense: jExpenseSlice,
    jMonthlyTarget: JMonthlyTargetSlice,

    //for POS dashboard
    PosExternalOrder: PosExternalOrder,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
