import {
  LinearProgress,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JReports } from "../../../../../../app/slices/jomla/reportsSlice";

const JRepTable = () => {
  const dispatch = useDispatch();

  const _JRep = useSelector((state) => state.jReports.jRep);
  const loading = useSelector((state) => state.jReports.loading);
  const reportTypes = useSelector((state) => state.jReports.reportTypes);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "name",
      headerName: "اسم المندوب",
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="h4">{params.row.name}</Typography>;
      },
    },
    {
      field: "mobile",
      headerName: "رقم الهاتف",
      flex: 1,
    },
    {
      field: "j_cash",
      headerName: "الكاش (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.j_cash?.toLocaleString()}</Typography>;
      },
    },
    {
      field: "pos_count",
      headerName: "نقاط البيع التابعة للمندوب",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>{params.row.pos_count?.toLocaleString()}</Typography>
        );
      },
    },
    {
      field: "pos_j_balance",
      headerName: "ديون نقطة البيع",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>{params.row.pos_j_balance?.toLocaleString()}</Typography>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JReports.jRep());
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, reportTypes]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير المندوبين | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Box sx={{ height: "80vh", width: "100%" }}>
          <DataGrid
            rows={
              _JRep.data?.map((x, index) => ({ ...x, index: index + 1 })) ?? []
            }
            loading={loading}
            columns={columns}
            components={{
              LoadingOverlay: LinearProgress,
              Toolbar: customToolbar,
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default JRepTable;
