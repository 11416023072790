import {
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JReports } from "../../../../../../app/slices/jomla/reportsSlice";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";

const JRepStatisticsTable = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    startDate: dayjs().format("YYYY-MM-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  const _JRep = useSelector((state) => state.jReports.jRepStatistics);
  const loading = useSelector((state) => state.jReports.loading);
  const reportTypes = useSelector((state) => state.jReports.reportTypes);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "name",
      headerName: "اسم المندوب",
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="h4">{params.row.name}</Typography>;
      },
    },
    {
      field: "pos_count",
      headerName: "نقاط البيع التابعة للمندوب",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>{params.row.pos_count?.toLocaleString()}</Typography>
        );
      },
    },
    {
      field: "pos_orders_count",
      headerName: "عدد طلبات النقاط",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.pos_orders_count?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "pos_orders_amount",
      headerName: "قيمة طلبات النقاط (د.ع)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.pos_orders_amount?.toLocaleString()}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      JReports.jRepStatistics({
        start_date: search.startDate,
        end_date: search.endDate,
      })
    );
  }, [dispatch, reportTypes, search]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "مبيعات المندوبين | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    startDate: dayjs().format("2023-01-01"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                  });
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "80vh", width: "100%" }}>
              <DataGrid
                rows={
                  _JRep.data?.map((x, index) => ({ ...x, index: index + 1 })) ??
                  []
                }
                loading={loading}
                columns={columns}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JRepStatisticsTable;
