import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { BsFolder2Open } from "react-icons/bs";
import { setDialog } from "../../../../../app/slices/jomla/jGroupSlice";
import JGroupForm from "../../from";

const JGroupHeader = () => {
  const dispatch = useDispatch();

  const _JGroup = useSelector((state) => state.jGroup);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الاقسام الرئيسية
          </Typography>
        }
        avatar={<BsFolder2Open size={20} />}
        action={
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة مجموعة
            </Button>
          </Stack>
        }
      />
      {_JGroup.jGroup.dialog ? <JGroupForm /> : null}
    </Card>
  );
};

export default JGroupHeader;
