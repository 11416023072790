import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  jGroupActions,
  resetForm,
  setDialog,
} from "../../../../app/slices/jomla/jGroupSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JGroupForm = () => {
  const jGroup = useSelector((state) => state.jGroup.jGroup);
  const _SearchBody = useSelector((state) => state.jGroup.searchBody);
  // const _provinces = useSelector((state) => state.provinces.provinces.data);

  const isAdd = jGroup.form.id ? false : true;
  const { control, reset, handleSubmit } = useForm({
    defaultValues: jGroup.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(jGroupActions.create(data, _SearchBody));
    } else {
      dispatch(jGroupActions.update(data.id, data, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ...jGroup.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jGroup.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    // <Typography>form test</Typography>
    <Dialog
      open={jGroup.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JGroupForm;
