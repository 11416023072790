import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import {
  JInvoice,
  setChangeSupplierDialog,
  setSearchBody,
} from "../../../../../app/slices/jomla/invoiceSlice";
import dayjs from "dayjs";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { SelectJSupplier } from "../../../../../components/Selectors/jomla/SelectJSupplier";
import { SelectJItem } from "../../../../../components/Selectors/jomla/SelectJItem";
import { JItems } from "../../../../../app/slices/jomla/itemsSlice";
import useDebounce from "../../../../../components/hooks/useDebounce";
import { FaBoxOpen } from "react-icons/fa";

const JInvoiceTable = () => {
  const dispatch = useDispatch();

  const _JInvoice = useSelector((state) => state.jInvoice.jInvoices);
  const _SearchBody = useSelector((state) => state.jInvoice.searchBody);
  const _JSupplier = useSelector(
    (state) => state.jSupplier.jSuppliersList.data
  );
  const _JItems = useSelector((state) => state.JItems.JItemsList.data);
  const _ListSearchBody = useSelector(
    (state) => state.JItems.JItemsList.searchBody
  );

  const [search, setSearch] = useState({
    number: "",
    j_supplier_id: null,
    j_item_id: null,
    start_date: dayjs().format("2024-01-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const debouncedSearch = useDebounce(_ListSearchBody, 500);

  useEffect(() => {
    dispatch(JItems.getAllList(debouncedSearch));
  }, [debouncedSearch, dispatch]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_JInvoice.total_amount?.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JInvoice.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "number",
      headerName: "رقم العملية",
      flex: 1,
    },
    {
      field: "created_by_name",
      headerName: "انشاءت بواسطة",
      flex: 1,
    },
    {
      field: "supplier_name",
      headerName: "المورد",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString(),
    },
    {
      field: "discount",
      headerName: "الخصم (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.discount)?.toLocaleString(),
    },
    {
      field: "net_amount",
      headerName: "المبلغ الصافي (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.net_amount)?.toLocaleString(),
    },
    {
      field: "remaining_amount",
      headerName: "المبلغ المتبقي (IQD)",
      flex: 1,
      renderCell: (params) =>
        parseInt(params.row.remaining_amount)?.toLocaleString(),
    },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => dayjs(params.row.created_at).format("YYYY-MM-DD"),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => dispatch(JInvoice.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <Tooltip title="تغيير المودر" followCursor>
              <IconButton
                color={params.row.driver_name ? "error" : "default"}
                onClick={() => {
                  dispatch(
                    setChangeSupplierDialog({
                      j_invoice_id: params.row.id,
                      j_supplier_id: params.row.j_supplier_id,
                    })
                  );
                }}
              >
                <FaBoxOpen />
              </IconButton>
            </Tooltip>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JInvoice.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        number: search.number,
        j_supplier_id: search.j_supplier_id ?? "",
        j_item_id: search.j_item_id ?? "",
        start_date: search.start_date ?? "",
        end_date: search.end_date ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  number: e.target.value,
                });
              }}
              label="رقم العملية"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJSupplier
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_supplier_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JSupplier.filter((x) => x.id === search.j_supplier_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJItem
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_item_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JItems.filter((x) => x.id === search.j_item_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      number: "",
                      j_supplier_id: null,
                      j_item_id: null,
                      start_date: dayjs().format("2024-01-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              <DataGrid
                rows={
                  _JInvoice.data?.map((x, i) => {
                    return {
                      ...x,
                      index: i + 1 + pageSize * currentPage,
                    };
                  }) ?? []
                }
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_JInvoice.total}
                loading={_JInvoice.loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JInvoiceTable;
