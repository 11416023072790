import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "../notificationSlice";
import { factory } from "../../../api/apiFactory";
const jGroupApi = factory.get("jGroup");
const initialState = {
  searchBody: {
    name: "",
  },
  jGroup: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      name: "",
    },
  },
  jGroups: {
    loading: false,
    data: [],
    total: 0,
  },
  jGroupList: [],
};

export const jGroupSlice = createSlice({
  name: "jGroup",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.jGroup.dialog = !state.jGroup.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.jGroups.data = payload.data.data;
      state.jGroups.total = payload.data.total;
    },
    setDataList: (state, { payload }) => {
      state.jGroupList = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.jGroup.form = payload;
    },
    setLoading: (state, action) => {
      state.jGroups.loading = !state.jGroups.loading;
    },
    resetForm: (state, action) => {
      state.jGroup.form = initialState.jGroup.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = jGroupSlice.actions;

export default jGroupSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jGroup"));
    const res = await jGroupApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("jGroup"));
  } catch (err) {
    dispatch(setLoading("jGroup"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jGroup"));
    const res = await jGroupApi.getAll(params);
    dispatch(setDataList(res));
    dispatch(setLoading("jGroup"));
  } catch (err) {
    dispatch(setLoading("jGroup"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await jGroupApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("jGroup"));
    const res = await jGroupApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("jGroup"));
  } catch (err) {
    dispatch(setLoading("jGroup"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await jGroupApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog(_SearchBody));
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await jGroupApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const jGroupActions = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList
};
