import { forwardRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { SelectJSupplier } from "../../../../components/Selectors/jomla/SelectJSupplier";
import {
  JInvoice,
  setChangeSupplierDialog,
} from "../../../../app/slices/jomla/invoiceSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeSupplierForm = (props) => {
  const dispatch = useDispatch();

  const _ChangeSupplier = useSelector((state) => state.jInvoice.changeSupplier);
  const _SearchBody = useSelector((state) => state.jInvoice.searchBody);
  const _JSupplier = useSelector(
    (state) => state.jSupplier.jSuppliersList.data
  );
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _ChangeSupplier.form,
  });

  const onSubmit = (data) => {
    dispatch(
      JInvoice.changeSupplier(
        _ChangeSupplier.form.j_invoice_id,
        {
          j_supplier_id: data.j_supplier_id,
        },
        _SearchBody
      )
    );
  };

  useEffect(() => {
    reset({ ..._ChangeSupplier.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_ChangeSupplier.form]);

  const handleDialog = () => {
    dispatch(setChangeSupplierDialog());
  };

  return (
    <Dialog
      open={_ChangeSupplier.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          {"تعيين الطلب الى سائق"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_ChangeSupplier.loading && (
            <LinearProgress sx={{ marginBottom: 1 }} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="j_supplier_id"
                control={control}
                render={({ field }) => (
                  <SelectJSupplier
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("j_supplier_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _JSupplier.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={_ChangeSupplier.loading ? true : false}
                endIcon={
                  _ChangeSupplier.loading ? (
                    <CircularProgress size={25} />
                  ) : null
                }
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeSupplierForm;
