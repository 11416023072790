import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Chip,
  Autocomplete,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  TablePagination,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { User } from "../../../../app/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import PaidIcon from "@mui/icons-material/Paid";
import { MdOutlineClear } from "react-icons/md";
import { TbTransferIn } from "react-icons/tb";
import dayjs from "dayjs";
import {
  setInitialPosInfoDialog,
  setTransactionsSearchBody,
} from "../../../../app/slices/walletSlice";
import InitialPosInfoForm from "../../form";
import { SelectRepresentative } from "../../../../components/Selectors/SelectRepresentative";
import { SelectGroup } from "../../../../components/Selectors/SelectGroup";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";
import { setCityTable } from "../../../../app/slices/provincesSlice";
import { SelectCity } from "../../../../components/Selectors/SelectCity";
import { useTheme } from "@emotion/react";

const UsetTabel = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});
  const [reload, setReload] = useState(false);

  const _User = useSelector((state) => state.user.walletUsers);
  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);
  const _InitialPosInfo = useSelector(
    (state) => state.wallet.initialPosInfo.dialog
  );
  const _SearchBody = useSelector(
    (state) => state.wallet.transactionsSearchBody
  );
  const users = useSelector((state) => state.user.usersList.data);
  const _Group = useSelector((state) => state.group.groupsList.data);
  const _provinces = useSelector((state) => state.provinces.provinces.data);
  const _Cities = useSelector((state) => state.provinces.cities.data);

  useEffect(() => {
    const selectedCities = _provinces?.filter((x) =>
      x.id === _SearchBody.province_id ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
  }, [_SearchBody.province_id, _provinces, dispatch]);

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(
      User.WalletActivation({
        user_id: dialogActiveData.id,
        is_active: !dialogActiveData.wallet?.is_active,
      })
    ).then((x) => {
      setReload(!reload);
    });
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
  };

  const getUserType = (row) => {
    if (row.is_representative) return "مندوب";
    else if (row.pos_number) return "نقطة بيع";
    else return "مستخدم";
  };

  const userType = [
    { type: "pos", name: "نقاط البيع" },
    { type: "rep", name: "مندوب" },
  ];

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="column">
            <Typography>{params.row.name}</Typography>
            <Typography variant="caption">{params.row.app_version}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "pos_number",
      headerName: "رقم النقطة",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: " رقم الهاتف",
      flex: 1,
    },
    {
      field: "wallet",
      headerName: "معلومات المحفظة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="column"
            spacing={0}
            sx={{ justifyContent: "center" }}
          >
            <Typography>
              الرصيد : {parseInt(params.row.balance).toLocaleString()}
            </Typography>
            <Typography>
              الدين : {parseInt(params.row.debt).toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "is_representative",
      headerName: "نوع المستخدم",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{getUserType(params.row)}</Typography>;
      },
    },
    {
      field: "last_transaction",
      headerName: "آخر عملية مناقلة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="column"
            spacing={0}
            sx={{ justifyContent: "center" }}
          >
            <Typography>
              المبلغ :{" "}
              {parseInt(params.row.last_transaction_amount).toLocaleString()}
            </Typography>
            <Typography>
              التاريخ:{" "}
              {params.row.last_transaction_date
                ? dayjs(params.row.last_transaction_date).format("YYYY-MM-DD")
                : null}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "last_pay",
      headerName: "آخر عملية دفع",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="column"
            spacing={0}
            sx={{ justifyContent: "center" }}
          >
            <Typography>
              المبلغ : {parseInt(params.row.last_pay_amount).toLocaleString()}
            </Typography>
            <Typography>
              التاريخ :
              {params.row.last_pay_date
                ? dayjs(params.row.last_pay_date).format("YYYY-MM-DD")
                : null}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "wallet_is_active",
      headerName: "حالة المحفظة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_active ? "فعال" : "غير فعال"}
            color={params.row.is_active ? "success" : "error"}
            onClick={() => {
              handleActiveClick(params.row);
            }}
          ></Chip>
        );
      },
    },
    {
      field: "has_product_order",
      headerName: "طلبات المنصة !",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip
            title={
              params.row.count > 0
                ? "لديه طلبات من المنصة"
                : "ليس لديه طلبات من المنصة"
            }
          >
            <Chip
              label={params.row.count > 0 ? "نعم" : "كلا"}
              color={params.row.count > 0 ? "info" : "default"}
            ></Chip>
          </Tooltip>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="كشف الحساب">
              <IconButton
                variant="contained"
                color="warning"
                size="small"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  localStorage.setItem("isWholeSale", "0");
                  goTo("/app/wallets/transactions/" + params.row.id);
                }}
              >
                <PaidIcon color="success" />
              </IconButton>
            </Tooltip>
            {params.row.pos_id ? (
              <Tooltip title="تحديث حساب نقطة البيع">
                <IconButton
                  variant="contained"
                  color="info"
                  size="small"
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    dispatch(setInitialPosInfoDialog(params.row.pos_id))
                  }
                >
                  <TbTransferIn />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(User.getRepAndPos(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch, reload]); //search

  const navigate = useNavigate();

  const goTo = (path) => {
    navigate(path);
  };

  return (
    <Card>
      <CardContent>
        {_InitialPosInfo && <InitialPosInfoForm />}
        <ConfirmationDialog
          open={openActive}
          title="تاكيد"
          message={
            dialogActiveData.wallet?.is_active
              ? "هل انت متاكد من الغاء تفعيل المحفظة"
              : "هل انت متاكد من تفعيل المحفظة"
          }
          name={dialogActiveData.user_name}
          confirmText="تاكيد"
          cancelText="الغاء"
          onConfirm={() => handleConfirmActive()}
          onCancel={handleCancelActive}
        />
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item md={2} xs={12}>
            <TextField
              size="small"
              value={_SearchBody?.search}
              onChange={(e) => {
                dispatch(
                  setTransactionsSearchBody({
                    ..._SearchBody,
                    search: e.target.value,
                  })
                );
              }}
              label={
                _SearchBody?.user_type === "pos"
                  ? "اسم او رقم النقطة"
                  : "اسم المندوب"
              }
              fullWidth
            />
          </Grid>
          {_SearchBody?.user_type === "rep"
            ? null
            : (_Role === "super_admin" || _Role === "admin") && (
                <Grid item xs={12} md={2}>
                  <SelectRepresentative
                    onChange={(e, newValue) => {
                      dispatch(
                        setTransactionsSearchBody({
                          ..._SearchBody,
                          rep_id: newValue ? newValue.id : "",
                        })
                      );
                    }}
                    value={
                      users.filter(
                        (user) => user.id === _SearchBody.rep_id
                      )[0] ?? null
                    }
                  />
                </Grid>
              )}
          {_SearchBody?.user_type === "rep" ? null : (
            <Grid item xs={12} md={2}>
              <SelectGroup
                onChange={(e, newValue) => {
                  dispatch(
                    setTransactionsSearchBody({
                      ..._SearchBody,
                      group_id: newValue ? newValue.id : "",
                    })
                  );
                }}
                value={
                  _Group.filter(
                    (group) => group.id === _SearchBody.group_id
                  )[0] ?? null
                }
              />
            </Grid>
          )}
          <Grid item xs={12} md={2}>
            <Autocomplete
              disableClearable
              options={userType}
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderInput={(params) => (
                <TextField {...params} label="نوع المستخدم" size="small" />
              )}
              onChange={(e, newValue) => {
                dispatch(
                  setTransactionsSearchBody({
                    user_type: newValue ? newValue.type : null,
                    skip: 0,
                    take: pageSize,
                  })
                );
                setCurrentPage(0);
              }}
              value={
                userType.filter(
                  (type) => type.type === _SearchBody.user_type
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectProvince
              onChange={(e, newValue) => {
                dispatch(
                  setTransactionsSearchBody({
                    ..._SearchBody,
                    province_id: newValue ? newValue.id : null,
                  })
                );
              }}
              value={
                _provinces?.filter(
                  (x) => x.id === _SearchBody.province_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectCity
              onChange={(e, newValue) => {
                dispatch(
                  setTransactionsSearchBody({
                    ..._SearchBody,
                    city_id: newValue ? newValue.id : null,
                  })
                );
              }}
              value={
                _Cities?.filter((x) => x.id === _SearchBody.city_id)[0] ?? null
              }
            />
          </Grid>
          {_SearchBody?.user_type === "rep" ? null : (
            <Grid item xs={12} md={2}>
              <ToggleButtonGroup
                fullWidth
                size="small"
                value={_SearchBody?.has_products}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    dispatch(
                      setTransactionsSearchBody({
                        ..._SearchBody,
                        has_products: newValue,
                      })
                    );
                  }
                }}
                aria-label="text alignment"
              >
                <ToggleButton value="0" aria-label="left aligned">
                  الكل
                </ToggleButton>
                <ToggleButton value="1" aria-label="centered">
                  طلبات المنصة
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
          <Grid item xs={12} md={1.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              {_Role === "representative" ? null : (
                <Tooltip title="تصدير ملف اكسل">
                  <IconButton
                    onClick={() => {
                      dispatch(User.exportExcel(_SearchBody));
                    }}
                  >
                    <BsFillFileEarmarkExcelFill />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    dispatch(
                      setTransactionsSearchBody({
                        user_type: "pos",
                        skip: 0,
                        take: 15,
                      })
                    );
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {smallScreen ? (
            _User?.data?.length > 0 ? (
              <Grid item xs={12}>
                {_User.loading ? <LinearProgress /> : null}
                {_User.data.map((data, index) => (
                  <Card sx={{ marginBottom: 0.5 }}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Typography variant="h4" color="primary">
                              {data?.name}
                            </Typography>
                            <Typography variant="h5">
                              {data?.pos_number}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم الهاتف: <strong>{data?.mobile}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم اصدار التطبيق:{" "}
                            <strong>{data?.app_version}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            نوع المستخدم: <strong>{getUserType(data)}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            الرصيد :{" "}
                            <strong>
                              {parseInt(data.balance).toLocaleString()} IQD
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            الدين :{" "}
                            <strong>
                              {parseInt(data.debt).toLocaleString()} IQD
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم الشريحة:{" "}
                            <strong>{data?.sim_card_number}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            اخر عملية مناقلة:{" "}
                            <strong>
                              {" "}
                              {parseInt(
                                data.last_transaction_amount
                              ).toLocaleString()}{" "}
                              IQD
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            تارخ العملية:{" "}
                            <strong>
                              {" "}
                              {data.last_transaction_date
                                ? dayjs(data.last_transaction_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : null}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            اخر عملية دفع:{" "}
                            <strong>
                              {" "}
                              {parseInt(
                                data.last_pay_amount
                              ).toLocaleString()}{" "}
                              IQD
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            تارخ العملية:{" "}
                            <strong>
                              {" "}
                              {data.last_pay_date
                                ? dayjs(data.last_pay_date).format("YYYY-MM-DD")
                                : null}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            حالة المحفظة:{" "}
                            <Chip
                              label={data.is_active ? "فعال" : "غير فعال"}
                              color={data.is_active ? "success" : "error"}
                              onClick={() => handleActiveClick(data)}
                            ></Chip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            طلبات المنصة:{" "}
                            <Chip
                              label={data.count > 0 ? "نعم" : "كلا"}
                              color={data.count > 0 ? "info" : "default"}
                            ></Chip>
                          </Typography>
                        </Grid>
                        {/* <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="caption" color="primary">
                            {dayjs(data?.created_at).format("YYYY-MM-DD")}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                localStorage.setItem("isWholeSale", "0");
                                goTo("/app/wallets/transactions/" + data.id);
                              }}
                            >
                              كشف الحساب
                            </Button>

                            {data.pos_id ? (
                              <Tooltip title="تحديث حساب نقطة البيع">
                                <IconButton
                                  variant="contained"
                                  color="info"
                                  size="small"
                                  style={{ marginLeft: 10 }}
                                  onClick={() =>
                                    dispatch(
                                      setInitialPosInfoDialog(data.pos_id)
                                    )
                                  }
                                >
                                  <TbTransferIn />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Card sx={{ mt: 2, width: "100%" }}>
                  <TablePagination
                    component="div"
                    count={_User.total}
                    rowsPerPage={pageSize}
                    page={currentPage}
                    onPageChange={(e, newPage) => setCurrentPage(newPage)}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={(event) => {
                      const newRowsPerPage = parseInt(event.target.value, 10);
                      setPageSize(newRowsPerPage);
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <Typography m={2} variant="h5" color="primary" align="center">
                    لا توجد بيانات
                  </Typography>
                </Card>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Box sx={{ height: "70vh", width: "100%" }}>
                <DataGrid
                  rows={
                    _User.data?.map((x, index) => ({
                      ...x,
                      index: index + 1,
                    })) ?? []
                  }
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_User.total}
                  loading={_User.loading}
                  rowHeight={65}
                  pagination
                  paginationMode="server"
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                    dispatch(
                      setTransactionsSearchBody({
                        ..._SearchBody,
                        skip: newPage * pageSize,
                      })
                    );
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                    dispatch(
                      setTransactionsSearchBody({
                        ..._SearchBody,
                        take: newPageSize,
                      })
                    );
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UsetTabel;
