import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClear } from "react-icons/md";
import {
  JTransaction,
  setSearchBody,
} from "../../../../../../app/slices/jomla/transactionsSlice";
import dayjs from "dayjs";
import { SelectJSupplier } from "../../../../../../components/Selectors/jomla/SelectJSupplier";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import { SelectJTransaction } from "../../../../../../components/Selectors/jomla/SelectTransactionTypes";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useParams } from "react-router";
import { JSupplier } from "../../../../../../app/slices/jomla/supplierSlice";
import { JInvoice } from "../../../../../../app/slices/jomla/invoiceSlice";
import { GiTakeMyMoney } from "react-icons/gi";
import JInvoiceForm from "../../../../Invoice/form";

const JTransactionTable = () => {
  const dispatch = useDispatch();
  const { supplier_id } = useParams();

  const _JTransaction = useSelector(
    (state) => state.jTransaction.jTransactions
  );
  const _SearchBody = useSelector((state) => state.jTransaction.searchBody);
  const _JSuppliers = useSelector(
    (state) => state.jSupplier.jSuppliersList.data
  );
  const _JTransactionTypes = useSelector(
    (state) => state.jTransaction.jTransactionTypes.data
  );
  const jInvoiceDialog = useSelector((state) => state.jInvoice);

  const [search, setSearch] = useState({
    number: "",
    j_supplier_id: null,
    j_type_id: null,
    start_date: dayjs().format("2024-01-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_JTransaction.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "number",
      headerName: "رقم المعاملة",
      flex: 0.6,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => params.row.amount?.toLocaleString(),
    },
    {
      field: "j_type_ar",
      headerName: "نوع المعاملة",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="body" textAlign="center">
            {params.row.j_type_ar}
          </Typography>
          {params.row.j_type_ar === "شراء مواد" ? (
            <IconButton
              onClick={() => dispatch(JInvoice.getById(params.row.j_invoice_id))}
            >
              <GiTakeMyMoney />
            </IconButton>
          ) : null}
        </Box>
      ),
    },
    {
      field: "created_by_name",
      headerName: "انشئت بواسطة",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.note}>
          <Typography variant="body" textAlign="center">
            {params.row.created_by_name?.toLocaleString()}
            {"  "}
            {params.row.note ? (
              <IoMdInformationCircleOutline size={20} />
            ) : null}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "j_supplier_name",
      headerName: "المورد",
      flex: 1,
    },
    {
      field: "supplier_balance",
      headerName: "رصيد المورد قبل",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body" textAlign="center">
            {params.row.supplier_balance?.toLocaleString()}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "new_supplier_balance",
      headerName: "رصيد المورد بعد",
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="start">
          <Typography variant="body" textAlign="center">
            {params.row.new_supplier_balance?.toLocaleString()}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="body">
            {dayjs(params.row.created_at).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="subtitle2">
            {dayjs(params.row.created_at).format("hh:mm a")}
          </Typography>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JTransaction.getAll(_SearchBody));
      dispatch(JSupplier.getById(supplier_id));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch, supplier_id]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        number: search.number,
        supplier_id: supplier_id,
        j_type_id: search.j_type_id ?? "",
        start_date: search.start_date ?? "",
        end_date: search.end_date ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search, supplier_id]);

  return (
    <Card>
      {jInvoiceDialog.jInvoice.dialog ? <JInvoiceForm readOnly={true} /> : null}
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              type="number"
              size="small"
              value={search.number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  number: e.target.value,
                });
              }}
              label="رقم العملية"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectJTransaction
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_type_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JTransactionTypes.filter(
                  (x) => x.id === search.j_type_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectJSupplier
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_supplier_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JSuppliers.filter((x) => x.id === search.j_supplier_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      number: "",
                      j_supplier_id: null,
                      j_type_id: null,
                      start_date: dayjs().format("2024-01-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "71vh", width: "100%" }}>
              {_JTransaction.data ? (
                <DataGrid
                  rows={_JTransaction.data.map((x, i) => {
                    return {
                      ...x,
                      index: i + 1 + pageSize * currentPage,
                    };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_JTransaction.total}
                  loading={_JTransaction.loading}
                  rowHeight={60}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: CustomFooter,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JTransactionTable;
