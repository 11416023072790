import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { Expense } from "./expenseSlice";
const expenseTypesApi = factory.get("expenseTypes");
const initialState = {
  searchBody: {},
  dialog: false,
  expenseTypes: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
    },
  },
  expensesTypes: {
    loading: false,
    data: [],
    total: 0,
  },
  expenseTypesList: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const expenseTypesSlice = createSlice({
  name: "expenseTypes",
  initialState,
  reducers: {
    setOpenDialog: (state, action) => {
      state.dialog = !state.dialog;
    },
    setTypesDialog: (state, action) => {
      state.expenseTypes.dialog = !state.expenseTypes.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.expensesTypes.data = payload.data.data;
      state.expensesTypes.total = payload.data.total;
      state.expensesTypes.total_amount = payload.data.total_amount;
    },
    setDataList: (state, { payload }) => {
      state.expenseTypesList.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.expenseTypes.form.id = payload.id;
      state.expenseTypes.form.name = payload.name;
      state.expenseTypes.form.logo = payload.logo;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.expenseTypes.form = initialState.expenseTypes.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setTypesDialog,
  setOpenDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = expenseTypesSlice.actions;

export default expenseTypesSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("expenseTypes"));
    const res = await expenseTypesApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("expenseTypes"));
  } catch (err) {
    dispatch(setLoading("expenseTypes"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("expenseTypes"));
    const res = await expenseTypesApi.getAllList(params);
    dispatch(setDataList(res));
    dispatch(setLoading("expenseTypes"));
  } catch (err) {
    dispatch(setLoading("expenseTypes"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id) => async (dispatch) => {
  try {
    await expenseTypesApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll());
    dispatch(Expense.getAllExpense());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("expenseTypes"));
    const res = await expenseTypesApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setTypesDialog());
    dispatch(setLoading("expenseTypes"));
  } catch (err) {
    dispatch(setLoading("expenseTypes"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await expenseTypesApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setTypesDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data) => async (dispatch) => {
  try {
    await expenseTypesApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setTypesDialog());
    dispatch(getAll());
    dispatch(Expense.getAllExpense());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const ExpenseTypes = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
};
