import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { BiTrash, BiEdit } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import {
  JExpenseActions,
  setSearchBody,
} from "../../../../../app/slices/jomla/expenseSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { SelectExpenseTypes } from "../../../../../components/Selectors/SelectExpensType";
import DateRangepicker from "../../../../../helpers/dateRangePicker";

const JExpenseTable = () => {
  const [search, setSearch] = useState({
    spending_type_id: null,
    search: "",
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const dispatch = useDispatch();

  const _JExpense = useSelector((state) => state.jExpense.jExpenses);
  const loading = useSelector((state) => state.jExpense.jExpense.loading);
  const _SearchBody = useSelector((state) => state.jExpense.searchBody);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JExpenseActions.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "spending_type",
      headerName: "نوع المصاريف",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.spending_type?.name}</>;
      },
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row.amount).toLocaleString()}</>;
      },
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return <>{dayjs(params.row?.date).format("YYYY-MM-DD")}</>;
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => dispatch(JExpenseActions.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JExpenseActions.getAllExpense(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        spending_type_id: search.spending_type_id
          ? search.spending_type_id.id
          : "",
        start_date: search.start_date,
        end_date: search.end_date,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          المجموع الكلي : {_JExpense.total_amount?.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من الحذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectExpenseTypes
              value={search.spending_type_id}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  spending_type_id: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    spending_type_id: null,
                    search: "",
                    start_date: dayjs().format("YYYY-MM-01"),
                    end_date: dayjs().format("YYYY-MM-DD"),
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={
                  _JExpense.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  })) ?? []
                }
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_JExpense.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JExpenseTable;
