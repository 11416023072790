import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-invoice?${params}`);
  },
  getAllList() {
    return api.get(`/api/v1/j-invoice/list`);
  },
  getById(id) {
    return api.get(`/api/v1/j-invoice/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/j-invoice/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/j-invoice`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/j-invoice/${id}`);
  },
  changeSupplier(id, data) {
    return api.put(`/api/v1/j-invoice/supplier-change/${id}`, data);
  },
};
