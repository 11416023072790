import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  InputAdornment,
  FormControlLabel,
  Switch,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setDialog, resetForm, Items } from "../../../app/slices/itemsSlice";
import { Controller, useForm } from "react-hook-form";
import { Stack } from "@mui/system";
import FilePondInput from "../../../helpers/FilePondInput";
import { SelectSubCategory } from "../../../components/Selectors/SelectSubCategory";
import { NumericFormat } from "react-number-format";
import { SelectSimCard } from "../../../components/Selectors/SelectSimCard";
import { SelectProvince } from "../../../components/Selectors/SelectProvince";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ItemsForm = () => {
  const [isChecked, setIsChecked] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const _Items = useSelector((state) => state.items.item);
  const _Group = useSelector((state) => state.group.groupsList);
  const _SearchBody = useSelector((state) => state.items.searchBody);
  const _SimCard = useSelector((state) => state.simCard.simCards);
  const _provinces = useSelector((state) => state.provinces.provinces.data);

  const isAdd = _Items.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: _Items.form,
  });

  useEffect(() => {
    if (!isAdd) setIsChecked(_Group);
  }, [_Group]);

  useEffect(() => {
    if (!isAdd) setGroups(_Items.form.groups);
  }, [isAdd]);

  useEffect(() => {
    const cleanedData = groups.filter((obj) => {
      const price = parseFloat(obj.price);
      return !isNaN(price) && isFinite(price);
    });
  }, [groups]);

  const onSubmit = (data) => {
    setLoading(true);
    const cleanedData = groups.filter((obj) => {
      const price = parseFloat(obj.price);
      return !isNaN(price) && isFinite(price);
    });
    const formData = new FormData();
    if (!isAdd) formData.append("id", data.id);
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("name", data.name);
    formData.append("groups", JSON.stringify(cleanedData));
    formData.append("sub_category_id", data.sub_category_id);
    formData.append("selling_price", 0);
    formData.append("description", data.description);
    formData.append("sequence", data.sequence);
    formData.append("ussd", data.ussd);
    formData.append("discount_percentage", data.discount_percentage);
    formData.append("max_discount_price", data.max_discount_price);
    formData.append("max_selling_per_day", data.max_selling_per_day);
    formData.append("is_hidden", data.is_hidden ? 1 : 0);
    formData.append("credit_amount", data.credit_amount);
    formData.append("province_ids", JSON.stringify(data.provinces));
    formData.append("sim_card", JSON.stringify(data.sim_card));
    if (data.logo && data.logo[0].name) formData.append("logo", data.logo[0]);
    if (isAdd) {
      dispatch(Items.create(formData)).then(() => setLoading(false));
    } else {
      dispatch(Items.update(data.id, formData, _SearchBody)).then(() =>
        setLoading(false)
      );
    }
  };
  useEffect(() => {
    reset({ ..._Items.form });
  }, [_Items.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  const isCheck = (group_id) => {
    return groups.filter((x) => x.group_id == group_id).length > 0;
  };
  const getGroupPrice = (group_id) => {
    return groups.filter((x) => x.group_id == group_id).length > 0
      ? groups.filter((x) => x.group_id == group_id)[0].price
      : "";
  };
  const handleChecked = (e, group_id) => {
    var check = e.target.checked;
    if (check) setGroups([...groups, { group_id: group_id, price: 0 }]);
    else {
      const newGroups = groups.filter((x) => x.group_id != group_id);
      setGroups(newGroups);
    }
  };

  const handlePriceChange = (e, group_id) => {
    const newGroups = groups.filter((x) => x.group_id != group_id);
    newGroups.push({ group_id: group_id, price: e.target.value });
    setGroups(newGroups);
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_Items.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_Items.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            {isAdd ? null : (
              <Grid item xs={12} md={12}>
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Controller
                    name="sub_category"
                    control={control}
                    render={({ field }) => (
                      <Typography>
                        الصفحة الثانوية: {field.value ? field.value.name : ""}
                      </Typography>
                    )}
                  />
                  <Controller
                    name="sub_category_logo"
                    control={control}
                    render={({ field }) => (
                      <Box
                        component="img"
                        src={field.value}
                        sx={{ width: 200 }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Controller
                name="provinces"
                control={control}
                render={({ field }) => (
                  <SelectProvince
                    multiple
                    onChange={(e, newValue) => {
                      var ids = newValue.map((x) => x.id);
                      setValue("provinces", newValue ? ids : []);
                    }}
                    value={
                      _provinces?.filter((x) =>
                        field.value?.includes(x.id) ? field.value : null
                      )
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="sequence"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="التسلسل"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={_Items.form.is_transfer ? 6 : 12}>
              <Controller
                name="sub_category"
                control={control}
                render={({ field }) => (
                  <SelectSubCategory
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue(
                        "sub_category_id",
                        newValue ? newValue.id : null
                      );
                      setValue("sub_category", newValue ? newValue : null);
                    }}
                  />
                )}
              />
            </Grid>
            {_Items.form.is_transfer ? (
              <Grid item xs={12} md={6}>
                <Controller
                  name="sim_card"
                  control={control}
                  render={({ field }) => (
                    <SelectSimCard
                      multiple
                      onChange={(e, newValue) => {
                        var ids = newValue.map((x) => x.id);
                        setValue("sim_card", newValue ? ids : null);
                      }}
                      value={_SimCard.data?.filter((x) =>
                        field.value?.includes(x.id) ? field.value : null
                      )}
                    />
                  )}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الوصف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="ussd"
                control={control}
                sx={{ direction: "ltr" }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="كود الادخال"
                    placeholder="*133*"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="max_selling_per_day"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الحد الاعلى للمبيعات اليومية"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="credit_amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="القيمة"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="discount_percentage"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="نسبة الخصم"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="max_discount_price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الحد الاعلى لقيمة الخصم"
                    fullWidth
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider textAlign="center" sx={{ marginTop: 1 }}>
                المجموعات
              </Divider>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="groups"
                control={control}
                render={({ field }) =>
                  _Group.data ? (
                    <Grid container spacing={1}>
                      {_Group.data.map((group, index) => (
                        <Grid item xs={3} md={3} key={index}>
                          <Stack>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheck(group.id)}
                                  onChange={(e) => handleChecked(e, group.id)}
                                />
                              }
                              label={group.name}
                            />

                            <Box sx={{ marginBottom: 2 }}>
                              <TextField
                                disabled={!isCheck(group.id)}
                                required
                                size="small"
                                label={`السعر: (${group.name})`}
                                fullWidth
                                // {...field}
                                value={getGroupPrice(group.id)}
                                onChange={(e) => handlePriceChange(e, group.id)}
                                error={
                                  getGroupPrice(group.id) &&
                                  watch("buying_price") >
                                  getGroupPrice(group.id)
                                }
                                helperText={
                                  getGroupPrice(group.id) &&
                                    watch("buying_price") >
                                    getGroupPrice(group.id)
                                    ? `سعر البيع يجب ان يكون اعلى من سعر الشراء  (${watch(
                                      "buying_price"
                                    )} IQD)`
                                    : null
                                }
                              />
                            </Box>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <CircularProgress color="inherit" size={20} />
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="is_hidden"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_hidden", e.target.checked);
                        }}
                      />
                    }
                    label="اخفاء المنتج !"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="logo"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center", paddingBottom: 2 }}
                    >
                      {isAdd ? null : (
                        <Box
                          component="img"
                          src={field.value}
                          sx={{ width: 200 }}
                        />
                      )}
                    </Stack>
                    <FilePondInput field={field} required={isAdd} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ItemsForm;
