import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  InputAdornment,
} from "@mui/material";
import { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { NumericFormat } from "react-number-format";
import {
  JClearance,
  setJTransactionDialog,
} from "../../../../../app/slices/jomla/clearanceSlice";
import { SelectJSafe } from "../../../../../components/Selectors/jomla/SelectJSafe";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JTransactionForm = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(false);

  const _JTransaction = useSelector((state) => state.jClearance.jtransaction);
  const searchBody = useSelector((state) => state.jClearance.searchBody);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: _JTransaction.form,
  });

  const onSubmit = (data) => {
    data.user_id = user_id;
    dispatch(JClearance.addJTransaction(data, searchBody)).then((x) => {
      setReload(!reload);
    });
  };

  const handleDialog = () => {
    dispatch(setJTransactionDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: parseInt(values.value) || 0,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });
  return (
    <Dialog
      open={_JTransaction.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={"md"}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          اضافة حركة مالية
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
          <Grid item xs={12}>
              <Controller
                name="j_order_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم الطلب"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                اضافة
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JTransactionForm;
