import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./../notificationSlice";
import { factory } from "../../../api/apiFactory";
const jInvoiceApi = factory.get("jInvoice");
const initialState = {
  searchBody: {
    name: "",
  },
  jInvoice: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      j_supplier_id: null,
      amount: "",
      discount: 0,
      net_amount: "",
      cash_paid: 0,
      j_invoice_item: [
        {
          j_item_id: null,
          count: 0,
          price: 0,
          totalPrice: 0,
          expiry_date: null,
        },
      ],
    },
  },
  jInvoices: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  jInvoicesList: {
    loading: false,
    data: [],
    total: 0,
  },
  changeSupplier: {
    loading: false,
    dialog: false,
    form: {
      j_supplier_id: null,
      j_invoice_id: null,
    },
  },
};

export const jInvoiceSlice = createSlice({
  name: "JCategories",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.jInvoice.dialog = !state.jInvoice.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.jInvoices.data = payload.data.data;
      state.jInvoices.total = payload.data.total;
      state.jInvoices.total_amount = payload.data.total_amount;
    },
    setDatalist: (state, { payload }) => {
      state.jInvoicesList.data = payload.data.data;
      state.jInvoicesList.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.jInvoice.form.id = payload.id;
      state.jInvoice.form.j_supplier_id = payload.j_supplier_id;
      state.jInvoice.form.amount = payload.amount;
      state.jInvoice.form.discount = payload.discount;
      state.jInvoice.form.net_amount = payload.net_amount;
      state.jInvoice.form.cash_paid = payload.cash_paid;
      state.jInvoice.form.j_invoice_item = payload.j_items;
    },
    setLoading: (state, action) => {
      state.jInvoices.loading = !state.jInvoices.loading;
    },
    resetForm: (state, action) => {
      state.jInvoice.form = initialState.jInvoice.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setChangeSupplierLoading: (state, action) => {
      state.changeSupplier.loading = !state.changeSupplier.loading;
    },
    setChangeSupplierDialog: (state, { payload }) => {
      state.changeSupplier.dialog = !state.changeSupplier.dialog;
      if (payload) {
        state.changeSupplier.form = payload;
      } else {
        state.changeSupplier.form = {
          j_supplier_id: null,
          j_invoice_id: null,
        };
      }
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
  setChangeSupplierLoading,
  setChangeSupplierDialog,
} = jInvoiceSlice.actions;

export default jInvoiceSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jInvoice"));
    const res = await jInvoiceApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("jInvoice"));
  } catch (err) {
    dispatch(setLoading("jInvoice"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jInvoice"));
    const res = await jInvoiceApi.getAllList(params);
    dispatch(setDatalist(res));
    dispatch(setLoading("jInvoice"));
  } catch (err) {
    dispatch(setLoading("jInvoice"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await jInvoiceApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("jInvoice"));
    const res = await jInvoiceApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("jInvoice"));
  } catch (err) {
    dispatch(setLoading("jInvoice"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await jInvoiceApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await jInvoiceApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const changeSupplier = (id, data, searchBody) => async (dispatch) => {
  try {
    dispatch(setChangeSupplierLoading());
    const res = await jInvoiceApi.changeSupplier(id, data);
    dispatch(setChangeSupplierLoading());
    dispatch(
      showNotification({
        message: "تم تعديل الخصم",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
    dispatch(setChangeSupplierDialog());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setChangeSupplierLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JInvoice = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  changeSupplier,
};
