import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setOrderChangeHistoryDialog } from "../../../../app/slices/jomla/orderSlice";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderChangeHistoryDialog = () => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.orderChangeHistory);

  const handleDialog = () => {
    dispatch(setOrderChangeHistoryDialog({}));
  };

  const columns = [
    {
      field: "created_by_name",
      headerName: "المستخدم",
      flex: 1,
    },
    {
      field: "old",
      headerName: "القيمة القديمة",
      flex: 1,
      renderCell: (params) => parseInt(params.row.old)?.toLocaleString(),
    },
    {
      field: "new",
      headerName: "القيمة الجديدة",
      flex: 1,
      renderCell: (params) => parseInt(params.row.new)?.toLocaleString(),
    },
    {
      field: "type",
      headerName: "النوع",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={0}>
          <Typography variant="body1">
            {dayjs(params.row.created_at).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body1">
            {dayjs(params.row.created_at).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          {"سجل التعديلات"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Box sx={{ height: "66vh", width: "100%" }}>
          <DataGrid
            rows={_JOrder.data}
            columns={columns}
            rowCount={_JOrder.total}
            loading={_JOrder.loading}
            rowHeight={60}
            pagination
            paginationMode="client"
            selectionModel={[]}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderChangeHistoryDialog;
