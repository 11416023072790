import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  InputAdornment,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Stack,
  Autocomplete,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  JOrder,
  resetForm,
  setDialog,
} from "../../../../app/slices/jomla/orderSlice";
import { NumericFormat } from "react-number-format";
import { SelectPos } from "../../../../components/Selectors/SelectPos";
import { SelectJItem } from "../../../../components/Selectors/jomla/SelectJItem";
import { BiTrash } from "react-icons/bi";
import { showNotification } from "../../../../app/slices/notificationSlice";
import { JItems } from "../../../../app/slices/jomla/itemsSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JOrderForm = () => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.jOrder);
  const _SearchBody = useSelector((state) => state.jOrder.searchBody);
  const _POS = useSelector((state) => state.pointOfsales.posesList.data);
  const _JItem = useSelector((state) => state.JItems.JItemsList.data);

  const [loading, setLoading] = useState(false);

  const isAdd = _JOrder.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues: _JOrder.form,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "j_items",
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (isAdd) {
      dispatch(
        JOrder.create(
          {
            pos_user_id: data.pos_user_id,
            j_items: data.j_items.map((item) => {
              return {
                j_item_id: item.j_item_id,
                count: item.count,
                expiry_date: item.expiry_date ?? null,
              };
            }),
            note: data.note,
            admin_discount: data.admin_discount ?? 0,
          },
          _SearchBody
        )
      )
        .then((x) => setLoading(false))
        .catch((e) => setLoading(false));
    } else {
      dispatch(
        JOrder.update(
          data.id,
          {
            pos_user_id: data.pos_user_id,
            j_items: data.j_items.map((item) => {
              return {
                j_item_id: item.j_item_id,
                count: item.count,
                expiry_date: item.expiry_date ?? null,
              };
            }),
            note: data.note,
            admin_discount: data.admin_discount ?? 0,
          },
          _SearchBody
        )
      )
        .then(() => setLoading(false))
        .catch((e) => setLoading(false));
    }
  };

  useEffect(() => {
    dispatch(JItems.getAllList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({ ..._JOrder.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JOrder.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: parseInt(values.value),
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="xl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JOrder.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="pos_user_id"
                control={control}
                render={({ field }) => (
                  <SelectPos
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue(
                        "pos_user_id",
                        newValue ? newValue.user_id : null
                      );
                    }}
                    value={
                      _POS.filter((x) => x.user_id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"admin_discount"}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الخصم"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الملاحظات"
                    fullWidth
                    multiline
                    rows={3}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table>
                    <TableBody>
                      {fields.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell sx={{ width: "1%" }}>
                            {`${index + 1}- `}
                          </TableCell>
                          <TableCell sx={{ width: "40%" }}>
                            <Controller
                              control={control}
                              name={`j_items[${index}].j_item_id`}
                              defaultValue={item.j_item_id}
                              render={({ field }) => (
                                <SelectJItem
                                  {...field}
                                  required
                                  onChange={(e, newValue) => {
                                    if (
                                      !newValue.is_no_stock &&
                                      newValue.count < 1
                                    ) {
                                      dispatch(
                                        showNotification({
                                          message: "العدد غير كافي لهذا العنصر",
                                          type: "error",
                                        })
                                      );
                                      setValue(
                                        `j_items[${index}].j_item_id`,
                                        null
                                      );
                                      setValue(`j_items[${index}].price`, 0);
                                      setValue(
                                        `j_items[${index}].last_buying_price`,
                                        0
                                      );
                                    } else {
                                      setValue(
                                        `j_items[${index}].j_item_id`,
                                        newValue ? newValue.id : null
                                      );
                                      setValue(
                                        `j_items[${index}].price`,
                                        newValue ? newValue.price : 0
                                      );
                                      setValue(
                                        `j_items[${index}].last_buying_price`,
                                        newValue
                                          ? newValue.last_buying_price
                                          : 0
                                      );
                                      setValue(
                                        `j_items[${index}].expiry_date_list`,
                                        newValue ? newValue.expiry_date : []
                                      );
                                    }
                                  }}
                                  value={
                                    _JItem.filter(
                                      (x) => x.id === field.value
                                    )[0] ?? null
                                  }
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "20%" }}>
                            <Controller
                              control={control}
                              name={`j_items[${index}].price`}
                              defaultValue={item.price}
                              render={({ field }) => (
                                <TextField
                                  required
                                  disabled
                                  size="small"
                                  label="السعر"
                                  fullWidth
                                  value={field.value}
                                  onChange={(e) => {
                                    setValue(
                                      `j_items[${index}].price`,
                                      e.target.value
                                    );
                                    setValue(
                                      `j_items[${index}].totalPrice`,
                                      e.target.value *
                                      watch(`j_items[${index}].count`)
                                    );
                                    const amount = watch("j_items").reduce(
                                      (total, item) =>
                                        total + item.price * item.count,
                                      0
                                    );

                                    setValue("amount", amount);
                                    field.onChange(e);
                                  }}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        IQD
                                      </InputAdornment>
                                    ),
                                  }}
                                  helperText={`سعر اخر شراء: ${watch(
                                    `j_items[${index}].last_buying_price`
                                  )?.toLocaleString()}`}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "10%" }}>
                            <Controller
                              control={control}
                              name={`j_items[${index}].count`}
                              defaultValue={item.count}
                              render={({ field }) => (
                                <TextField
                                  required
                                  size="small"
                                  label="العدد"
                                  fullWidth
                                  // {...field}
                                  value={field.value}
                                  onChange={(e) => {
                                    setValue(
                                      `j_items[${index}].count`,
                                      e.target.value
                                    );
                                    setValue(
                                      `j_items[${index}].totalPrice`,
                                      e.target.value *
                                      watch(`j_items[${index}].price`)
                                    );
                                    const amount = watch("j_items").reduce(
                                      (total, item) =>
                                        total + item.price * item.count,
                                      0
                                    );
                                    setValue("amount", amount);
                                    field.onChange(e);
                                  }}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    startAdornment: (
                                      <InputAdornment position="start"></InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "15%" }}>
                            <Controller
                              control={control}
                              name={`j_items[${index}].totalPrice`}
                              defaultValue={item.count}
                              render={({ field }) => (
                                <TextField
                                  required
                                  size="small"
                                  label="السعر الكلي"
                                  fullWidth
                                  disabled
                                  {...field}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    startAdornment: (
                                      <InputAdornment position="start"></InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "20%" }}>
                            <Controller
                              name={`j_items[${index}].expiry_date`}
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  options={
                                    watch(
                                      `j_items[${index}].expiry_date_list`
                                    ) || []
                                  }
                                  getOptionLabel={(option) => option || ""}
                                  value={
                                    watch(
                                      `j_items[${index}].expiry_date_list`
                                    )?.filter((x) => x === field.value)[0] ??
                                    null
                                  }
                                  onChange={(e, newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="تاريخ الانتهاء"
                                      size="small"
                                    />
                                  )}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "1%" }}>
                            <Tooltip title="حذف الصف">
                              <IconButton
                                color="error"
                                onClick={() => {
                                  const removedValue =
                                    watch(`j_items[${index}].price`) *
                                    watch(`j_items[${index}].count`);
                                  setValue(
                                    "amount",
                                    getValues("amount") - removedValue
                                  );
                                  remove(index);
                                }}
                              >
                                <BiTrash />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        append({
                          j_item_id: "",
                          count: "",
                          price: "",
                          expiry_date: null,
                          expiry_date_list: [],
                        })
                      }
                    >
                      اضافة منتج جديد
                    </Button>
                    <Controller
                      control={control}
                      name={`amount`}
                      render={({ field }) => (
                        <TextField
                          required
                          size="small"
                          label="السعر الكلي للطلب"
                          disabled
                          {...field}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading ? true : false}
                endIcon={loading ? <CircularProgress size={25} /> : null}
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JOrderForm;
