import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const jPosApi = factory.get("jPos");
const initialState = {
  searchBody: {
    search: "",
    has_j_balance: null,
    j_rep_id: null,
  },
  loading: false,
  jPos: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
    total_debt: 0,
  },
};

export const jPosSlice = createSlice({
  name: "jPos",
  initialState,
  reducers: {
    setJPosTable: (state, { payload }) => {
      state.jPos.data = payload.data.data;
      state.jPos.total = payload.data.total;
      state.jPos.total_amount = payload.data.total_amount;
      state.jPos.total_debt = payload.data.total_debt;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const { setLoading, setSearchBody, setJPosTable, setSelectedReport } =
  jPosSlice.actions;

export default jPosSlice.reducer;

//axios
const jPos = (data) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await jPosApi.jPos(data);
    dispatch(setJPosTable(res));
    dispatch(setLoading());
  } catch (err) {
    dispatch(setLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await jPosApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `jomla-pos.xlsx`;
    a.click();
    dispatch(setLoading());
  } catch (err) {
    dispatch(setLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JPos = {
  jPos,
  exportExcel,
};
