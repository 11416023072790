import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, CircularProgress } from "@mui/material";
import { useEffect, forwardRef, Fragment } from "react";
import { styled } from "@mui/system";
import { IssuedCards } from "../../app/slices/issuedCardSlice";

export const SelectIssuedCardStatus = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const _IssuedCard = useSelector((state) => state.issuedCard.statusList);

  useEffect(() => {
    dispatch(IssuedCards.getStatus());
  }, [dispatch]);

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    borderBottom: "1px solid #e0e0e0",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <Autocomplete
      ref={ref}
      options={_IssuedCard || []}
      {...props}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="الحالة"
          size="small"
          required={props.required}
          error={props.error}
          helperText={props.helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {_IssuedCard.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderGroup={(params, key) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
});
