import { forwardRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  JOrder,
  resetForm,
  setChangeAdminNoteDialog,
} from "../../../../app/slices/jomla/orderSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeAdminNoteForm = (props) => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.changeAdminNote);
  const _SearchBody = useSelector((state) => state.jOrder.searchBody);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: _JOrder.form,
  });

  const onSubmit = (data) => {
    dispatch(
      JOrder.changeAdminNote(
        _JOrder.form.order_id,
        {
          admin_note: data.admin_note,
        },
        _SearchBody,
        props.orderDetails
      )
    );
  };

  useEffect(() => {
    reset({ ..._JOrder.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JOrder.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setChangeAdminNoteDialog({}));
  };

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="sm"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          {"ملاحظات الطلبية"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JOrder.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"admin_note"}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={_JOrder.loading ? true : false}
                endIcon={
                  _JOrder.loading ? <CircularProgress size={25} /> : null
                }
              >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeAdminNoteForm;
