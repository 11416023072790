import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
const userApi = factory.get("user");

const initialState = {
  searchBody: {
    user_type: "pos",
    take: 15,
    skip: 0,
  },
  user: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
      user_name: "",
      password: "",
      email: "",
      mobile: "",
      is_active: true,
      is_representative: false,
      is_j_rep: false,
      can_transfer: false,
      devices: 0,
      type: "",
      max_balance: 10000000,
      max_debt: 10000000,
      debt: 0,
      role: null,
      project_id: "",
      project_name: "",
      is_driver: false,
    },
  },
  users: {
    loading: false,
    data: [],
    total: 0,
  },
  walletUsers: {
    loading: false,
    data: [],
    total: 0,
  },
  usersList: {
    loading: false,
    data: [],
    total: 0,
  },
  jRepList: {
    loading: false,
    data: [],
    total: 0,
  },
  WalletActivation: false,
  userActivation: false,
  userInfo: { user_roles: [] },
  userInfoLoading: false,
  teamLeader: {
    loading: false,
    data: [],
    total: 0,
  },
  teamLeaders: {
    data: [],
    total: 0,
  },
  resetPasswordByAdmin: {
    dialog: false,
    user_id: null,
    form: {
      password: "",
      new_password: "",
    },
  },
  loginHistory: {
    userId: null,
    dialog: false,
    data: [],
  },
  driversList: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.user.dialog = !state.user.dialog;
    },
    setResetPasswordByAdminDialog: (state, { payload }) => {
      state.resetPasswordByAdmin.dialog = !state.resetPasswordByAdmin.dialog;
      state.resetPasswordByAdmin.user_id = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setById: (state, { payload }) => {
      state.user.form.id = payload.id;
      state.user.form.user_name = payload.user_name;
      state.user.form.name = payload.name;
      state.user.form.email = payload.email;
      state.user.form.mobile = payload.mobile;
      state.user.form.max_balance = payload.wallet.max_balance;
      state.user.form.max_debt = payload.wallet.max_debt;
      state.user.form.wallet = payload.wallet;
      state.user.form.is_representative = payload.is_representative;
      state.user.form.is_j_rep = payload.is_j_rep;
      state.user.form.pos_number = payload.pos_number;
      state.user.form.pos_id = payload.pos_id;
      state.user.form.can_transfer = payload.can_transfer;
      state.user.form.devices = payload.devices;
      state.user.form.role = payload.user_roles[0];
      state.user.form.project_id = payload.project_id || "";
      state.user.form.project_name = payload.project_name || "";
      state.user.form.is_driver = payload.is_driver;
    },
    setDataTable: (state, { payload }) => {
      state.users.data = payload.data.data;
      state.users.total = payload.data.total;
    },
    setWalletUsersDataTable: (state, { payload }) => {
      state.walletUsers.data = payload.data.data;
      state.walletUsers.total = payload.data.total;
    },
    setDataList: (state, { payload }) => {
      state.usersList.data = payload.data.data;
    },
    setJRepDataList: (state, { payload }) => {
      state.jRepList.data = payload.data.data;
    },
    setTeamLeaderDataTable: (state, { payload }) => {
      state.teamLeader.data = payload.data.data;
      state.teamLeader.total = payload.data.total;
    },
    setTeamLeadersDataTable: (state, { payload }) => {
      state.teamLeaders.data = payload.data.data;
      state.teamLeaders.total = payload.data.total;
    },
    resetForm: (state, action) => {
      state.user.form = initialState.user.form;
    },
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload;
      localStorage.setItem("role", payload.user_roles[0]);
    },
    setRole: (state, { payload }) => {
      state.role = payload;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setLoginHistoryDialog: (state, { payload }) => {
      state.loginHistory.dialog = !state.loginHistory.dialog;
      state.loginHistory.userId = payload;
    },
    setLoginHistory: (state, { payload }) => {
      state.loginHistory.data = payload.data.data;
    },
    setUserInfoLoading: (state, action) => {
      state.userInfoLoading = !state.userInfoLoading;
    },
    setDriversDataList: (state, { payload }) => {
      state.driversList.data = payload.data.data;
    },
  },
});

export const {
  setDialog,
  setById,
  setLoading,
  setDataTable,
  setErrors,
  setUser,
  resetForm,
  setUserInfo,
  setRole,
  setSearchBody,
  setTeamLeaderDataTable,
  setDataList,
  setResetPasswordByAdminDialog,
  setWalletUsersDataTable,
  setTeamLeadersDataTable,
  setLoginHistoryDialog,
  setLoginHistory,
  setUserInfoLoading,
  setDriversDataList,
  setJRepDataList,
} = usersSlice.actions;

export default usersSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    const res = await userApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(setLoading("user"));
    throw new Error(err);
  }
};
const getAllList = () => async (dispatch) => {
  try {
    dispatch(setLoading("usersList"));
    const res = await userApi.getAllList();
    dispatch(setDataList(res));
    dispatch(setLoading("usersList"));
  } catch (err) {
    dispatch(setLoading("usersList"));
    throw new Error(err);
  }
};
const getAllDriversList = () => async (dispatch) => {
  try {
    dispatch(setLoading("usersList"));
    const res = await userApi.getAllDriversList();
    dispatch(setDriversDataList(res));
    dispatch(setLoading("usersList"));
  } catch (err) {
    dispatch(setLoading("usersList"));
    throw new Error(err);
  }
};
const getRepList = () => async (dispatch) => {
  try {
    dispatch(setLoading("usersList"));
    const res = await userApi.getRepList();
    dispatch(setDataList(res));
    dispatch(setLoading("usersList"));
  } catch (err) {
    dispatch(setLoading("usersList"));
    throw new Error(err);
  }
};
const getJRepList = () => async (dispatch) => {
  try {
    dispatch(setLoading("usersList"));
    const res = await userApi.getJRepList();
    dispatch(setJRepDataList(res));
    dispatch(setLoading("usersList"));
  } catch (err) {
    dispatch(setLoading("usersList"));
    throw new Error(err);
  }
};
// const getTeamLeader = (params) => async (dispatch) => {
//   try {
//     dispatch(setLoading('user'));
//     const res = await userApi.getTeamLeader(params)
//     dispatch(setTeamLeaderDataTable(res));
//     dispatch(setLoading('user'));

//   } catch (err) {
//     dispatch(setLoading('user'));
//     throw new Error(err);
//   }
// };
// const getAllTeamLeaders = (params) => async (dispatch) => {
//   try {
//     dispatch(setLoading('user'));
//     const res = await userApi.setTeamLeadersDataTable(params)
//     dispatch(setTeamLeaderDataTable(res));
//     dispatch(setLoading('user'));

//   } catch (err) {
//     dispatch(setLoading('user'));
//     throw new Error(err);
//   }
// };

const getRepAndPos = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("walletUsers"));
    const res = await userApi.getRepAndPos(params);
    dispatch(setWalletUsersDataTable(res));
    dispatch(setLoading("walletUsers"));
  } catch (err) {
    dispatch(setLoading("walletUsers"));
    throw new Error(err);
  }
};

const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    const res = await userApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("user"));
    throw new Error(err);
  }
};
const getByIdData = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    const res = await userApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("user"));
    throw new Error(err);
  }
};

const login = (data) => async (dispatch) => {
  try {
    // dispatch(setLoading('users'));
    const res = await userApi.login(data);
    return Promise.resolve(res);
    // dispatch(setDataTable(res));
    // dispatch(setLoading('users'));
  } catch (err) {
    // dispatch(setLoading('users'));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const loginPos = (data) => async (dispatch) => {
  try {
    const res = await userApi.loginPos(data);
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const confirmLogin = (data) => async (dispatch) => {
  try {
    const res = await userApi.confirmLogin(data);
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const getIp = (data) => async (dispatch) => {
  try {
    const res = await userApi.getIp(data);
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const logout = () => async (dispatch) => {
  // try {
  //     const res = await userApi.logout()
  //     localStorage.removeItem('token')
  //     return Promise.resolve(res)
  // } catch (err) {
  //     dispatch(showNotification({
  //         message: err.response.data.message,
  //         type: "error"
  //     }));
  //     throw new Error(err);
  // }
};
const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("walletUsers"));
    const res = await userApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `wallets-${dayjs().format("YYYYMMDD")}.xlsx`;
    a.click();
    dispatch(setLoading("walletUsers"));
  } catch (err) {
    dispatch(setLoading("walletUsers"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const userActivation = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    await userApi.userActivation(params);
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const WalletActivation = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    await userApi.WalletActivation(params);
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await userApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await userApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await userApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const userInfo = () => async (dispatch) => {
  try {
    dispatch(setUserInfoLoading());
    const res = await userApi.userInfo();
    dispatch(setUserInfo(res.data.data[0]));
    dispatch(setUserInfoLoading());
  } catch (err) {
    dispatch(setUserInfoLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const updatePassowrd = (data) => async (dispatch) => {
  dispatch(setLoading("user"));
  try {
    await userApi.changePassword(data);
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
  } catch (err) {
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const resetPasswordByAdmin = (data) => async (dispatch) => {
  dispatch(setLoading("user"));
  try {
    await userApi.resetPasswordByAdmin(data);
    dispatch(setLoading("user"));
    dispatch(setResetPasswordByAdminDialog(null));
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
  } catch (err) {
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getLoginHistory = (userId) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    const res = await userApi.loginHistory(userId);
    dispatch(setLoginHistory(res));
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(setLoading("user"));
    throw new Error(err);
  }
};

export const User = {
  getAll,
  login,
  loginPos,
  confirmLogin,
  logout,
  WalletActivation,
  userActivation,
  getById,
  update,
  create,
  deleteById,
  userInfo,
  updatePassowrd,
  getByIdData,
  getIp,
  getRepAndPos,
  getRepList,
  exportExcel,
  // getTeamLeader,
  // addTeamLeader,
  getAllList,
  resetPasswordByAdmin,
  // getAllTeamLeaders,
  getLoginHistory,
  getAllDriversList,
  getJRepList,
};
