import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useEffect, forwardRef, Fragment } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Provinces } from "../../app/slices/provincesSlice";

export const SelectProvince = forwardRef((props, ref) => {
  const _provinces = useSelector((state) => state.provinces.provinces);
  const loading = useSelector((state) => state.provinces.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Provinces.getAll());
  }, []);

  return (
    <Autocomplete
      ref={ref}
      options={_provinces.data || []}
      {...props}
      size="small"
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          required={props.required}
          error={props.error}
          helperText={props.helperText}
          {...params}
          label="المحافظة"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
});
