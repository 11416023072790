import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "../notificationSlice";
import { factory } from "../../../api/apiFactory";
const jExpenseApi = factory.get("jExpense");
const initialState = {
  searchBody: {},
  jExpense: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      spending_type_id: null,
      amount: "",
      date: dayjs().format("YYYY-MM-DD"),
      note: "",
    },
  },
  jExpenses: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const jExpenseSlice = createSlice({
  name: "jExpense",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.jExpense.dialog = !state.jExpense.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.jExpenses.data = payload.data.data;
      state.jExpenses.total = payload.data.total;
      state.jExpenses.total_amount = payload.data.total_amount;
    },
    setById: (state, { payload }) => {
      state.jExpense.form.id = payload.id;
      state.jExpense.form.spending_type_id = payload.spending_type_id;
      state.jExpense.form.amount = payload.amount;
      state.jExpense.form.date = payload.date;
      state.jExpense.form.note = payload.note;
      state.jExpense.form.safe_id = payload.safe_id;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.jExpense.form = initialState.jExpense.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
} = jExpenseSlice.actions;

export default jExpenseSlice.reducer;

//axios
const getAllExpense = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jExpense"));
    const res = await jExpenseApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("jExpense"));
  } catch (err) {
    dispatch(setLoading("jExpense"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await jExpenseApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAllExpense(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("jExpense"));
    const res = await jExpenseApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("jExpense"));
  } catch (err) {
    dispatch(setLoading("jExpense"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, searchBody) => async (dispatch) => {
  try {
    await jExpenseApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAllExpense(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await jExpenseApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAllExpense(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JExpenseActions = {
  getAllExpense,
  deleteById,
  getById,
  create,
  update,
};
