import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./../notificationSlice";
import { factory } from "../../../api/apiFactory";
import { User } from "../userSlice";
const JClearanceApi = factory.get("jClearance");
const initialState = {
  searchBody: {
    name: "",
  },
  cashFromRep: {
    loading: false,
    dialog: false,
    form: {
      rep_id: null,
      amount: "",
    },
  },
  cashFromPos: {
    loading: false,
    dialog: false,
    form: {
      user_id: null,
      amount: "",
      note: "",
    },
  },
  jtransaction: {
    loading: false,
    dialog: false,
    form: {
      j_order_number: null,
      amount: "",
      note: "",
    },
  },
  jClearances: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  jPosTransaction: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
};

export const JClearanceSlice = createSlice({
  name: "jClearance",
  initialState,
  reducers: {
    setCashFromRepDialog: (state, action) => {
      state.cashFromRep.dialog = !state.cashFromRep.dialog;
    },
    setCashFromPosDialog: (state, action) => {
      state.cashFromPos.dialog = !state.cashFromPos.dialog;
    },
    setJTransactionDialog: (state, action) => {
      state.jtransaction.dialog = !state.jtransaction.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.jClearances.data = payload.data.data;
      state.jClearances.total = payload.data.total;
      state.jClearances.total_amount = payload.data.total_amount;
    },
    setPosTransactionsDataTable: (state, { payload }) => {
      state.jPosTransaction.data = payload.data.data;
      state.jPosTransaction.total = payload.data.total;
      state.jPosTransaction.total_amount = payload.data.total_amount;
    },
    setLoading: (state, action) => {
      state.jClearances.loading = !state.jClearances.loading;
    },
    resetCashFromRepForm: (state, action) => {
      state.cashFromRep.form = initialState.cashFromRep.form;
    },
    resetCashFromPosForm: (state, action) => {
      state.cashFromPos.form = initialState.cashFromPos.form;
    },
    resetJTransactionForm: (state, action) => {
      state.jtransaction.form = initialState.jtransaction.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setCashFromRepDialog,
  setCashFromPosDialog,
  setJTransactionDialog,
  setDataTable,
  setPosTransactionsDataTable,
  resetForm,
  setSearchBody,
} = JClearanceSlice.actions;

export default JClearanceSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("JClearance"));
    const res = await JClearanceApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("JClearance"));
  } catch (err) {
    dispatch(setLoading("JClearance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllPosTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("JClearance"));
    const res = await JClearanceApi.getPosTransactionsAll(params);
    dispatch(setPosTransactionsDataTable(res));
    dispatch(setLoading("JClearance"));
  } catch (err) {
    dispatch(setLoading("JClearance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const cashFromRep = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("JClearance"));
    await JClearanceApi.cashFromRep(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("JClearance"));
    dispatch(setCashFromRepDialog());
    dispatch(User.getByIdData(data.rep_id));
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("JClearance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const cashFromPos = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("JClearance"));
    await JClearanceApi.cashFromPos(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("JClearance"));
    dispatch(setCashFromPosDialog());
    dispatch(User.getByIdData(data.user_id));
    dispatch(getAllPosTransactions(_SearchBody));
  } catch (err) {
    dispatch(setLoading("JClearance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const addJTransaction = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("JClearance"));
    await JClearanceApi.addJTransaction(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("JClearance"));
    dispatch(setJTransactionDialog());
    dispatch(User.getByIdData(data.user_id));
    dispatch(getAllPosTransactions(_SearchBody));
  } catch (err) {
    dispatch(setLoading("JClearance"));
    dispatch(
      showNotification({
        message: err?.response?.data?.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JClearance = {
  getAll,
  cashFromPos,
  cashFromRep,
  getAllPosTransactions,
  addJTransaction
};
