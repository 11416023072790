import { Grid } from "@mui/material";
import React from "react";
import JGroupHeader from "./home/header";
import JGroupTable from "./home/table";

const JGroup = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JGroupHeader />
      </Grid>
      <Grid item xs={12}>
        <JGroupTable />
      </Grid>
    </Grid>
  );
};

export default JGroup;
