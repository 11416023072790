import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== "" && data[key] !== null && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/pin?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/pin/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/pin/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/pin`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/pin/${id}`);
  },
  importExcelFile(data) {
    return api.post(`/api/v1/pin/excel/`, data);
  },
  importCsvFile(data) {
    return api.post(`/api/v1/pin/csv/`, data);
  },
  checkExcelFile(data) {
    return api.post(`/api/v1/pin/check-excel/`, data);
  },
  replacePin(data) {
    return api.post(`/api/v1/pin/replace`, data);
  },
  updateExcelPrice(data) {
    return api.post(`/api/v1/pin/update-price`, data);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/pin?${params}`, {
      responseType: "blob",
    });
  },
};
