import { forwardRef, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Divider,
  DialogTitle,
  Box,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Container,
  Stack,
  tableCellClasses,
  styled,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setOrderPrintDialog } from "../../../../app/slices/jomla/orderSlice";
import dayjs from "dayjs";
import Logo from "../../../../assets/Logo";
import { useReactToPrint } from "react-to-print";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   // backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 1,
  // },
}));

const OrderPrintDialog = () => {
  const dispatch = useDispatch();

  const _JOrder = useSelector((state) => state.jOrder.orderPrint);

  const handleDialog = () => {
    dispatch(setOrderPrintDialog({}));
  };

  const printRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handlePrint();
    }, 300);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
    @page {
      size: A4;
      margin: 0mm;
    }
    body {
      direction: rtl; 
    }`,
  });

  return (
    <Dialog
      open={_JOrder.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          فاتورة بيع
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePrint()}
            sx={{ width: "20%" }}
          >
            طباعة
          </Button>
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <Container ref={printRef}>
          <Box sx={{ padding: 2 }}>
            <Box>
              <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h3" align="center">
                      شركة دجلة للتجارة العامة والتسويق الالكتروني
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={2} align="center">
                      فاتورة بيع
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Stack spacing={1}>
                      <Typography fontSize="17px" fontWeight="bold">
                        رقم الطلب:{" "}
                        <span
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          #{_JOrder?.data.number}
                        </span>
                      </Typography>
                      <Typography fontSize="17px" fontWeight="bold">
                        التاريخ:{" "}
                        <span
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            ontWeight: "bold",
                          }}
                        >
                          {dayjs(_JOrder?.data.created_at).format("YYYY-MM-DD")}
                        </span>
                      </Typography>
                      <Typography fontSize="17px" fontWeight="bold">
                        المندوب:{" "}
                        <span
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder?.data.pos_rep_name}
                        </span>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={5}>
                    <Stack spacing={1}>
                      <Typography fontSize="17px" fontWeight="bold">
                        نقطة البيع:{" "}
                        <span
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder?.data.user_name}
                        </span>
                      </Typography>
                      <Typography fontSize="17px" fontWeight="bold">
                        رقم الهاتف:{" "}
                        <span
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder?.data.pos_mobile}
                        </span>
                      </Typography>
                      <Typography fontSize="17px" fontWeight="bold">
                        العنوان:{" "}
                        <span
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder?.data.pos_address}
                        </span>
                      </Typography>
                      <Typography fontSize="17px" fontWeight="bold">
                        ديون النقطة:{" "}
                        <span
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder?.data.pos_j_balance?.toLocaleString()} (IQD)
                        </span>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3.5} display="flex" justifyContent="end">
                    <Box>
                      <Logo
                        style={{ width: 150, hight: 200, objectFit: "contain" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ marginBottom: 2 }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell
                          sx={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          #
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          اسم المنتج
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          القسم
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          السعر (IQD)
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          العدد
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          الخصم
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          sx={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          السعر الكلي (IQD)
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {_JOrder?.data?.items?.map((item, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              sx={{ fontWeight: "bold", fontSize: 15 }}
                            >
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                width: "25%",
                                fontWeight: "bold",
                                fontSize: 15,
                                textDecoration: item.is_canceled
                                  ? "line-through red"
                                  : "none",
                                textDecorationThickness: item.is_canceled
                                  ? "0.5px"
                                  : "initial",
                              }}
                            >
                              {item.j_item_name}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textDecoration: item.is_canceled
                                  ? "line-through red"
                                  : "none",
                                textDecorationThickness: item.is_canceled
                                  ? "0.5px"
                                  : "initial",
                              }}
                            >
                              {item.j_sub_category_name}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textDecoration: item.is_canceled
                                  ? "line-through red"
                                  : "none",
                                textDecorationThickness: item.is_canceled
                                  ? "0.5px"
                                  : "initial",
                              }}
                            >
                              {item.price?.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textDecoration: item.is_canceled
                                  ? "line-through red"
                                  : "none",
                                textDecorationThickness: item.is_canceled
                                  ? "0.5px"
                                  : "initial",
                              }}
                            >
                              {item.count?.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textDecoration: item.is_canceled
                                  ? "line-through red"
                                  : "none",
                                textDecorationThickness: item.is_canceled
                                  ? "0.5px"
                                  : "initial",
                              }}
                            >
                              {item.total_discount?.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              sx={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textDecoration: item.is_canceled
                                  ? "line-through red"
                                  : "none",
                                textDecorationThickness: item.is_canceled
                                  ? "0.5px"
                                  : "initial",
                              }}
                            >
                              {item.total?.toLocaleString()}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            color: "warning.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          المجموع:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          sx={{
                            color: "warning.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder.data?.amount?.toLocaleString()} (IQD)
                        </Typography>
                      </Grid>
                      {_JOrder.data?.discount > 0 && (
                        <>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                color: "error.main",
                                fontSize: 15,
                                fontWeight: "bold",
                              }}
                            >
                              الخصم:
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              sx={{
                                color: "error.main",
                                fontSize: 15,
                                fontWeight: "bold",
                              }}
                            >
                              {_JOrder.data?.discount?.toLocaleString()} (IQD)
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {_JOrder.data?.admin_discount > 0 && (
                        <>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                color: "error.main",
                                fontSize: 15,
                                fontWeight: "bold",
                              }}
                            >
                              خصم الشركة:
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              sx={{
                                color: "error.main",
                                fontSize: 15,
                                fontWeight: "bold",
                              }}
                            >
                              {_JOrder.data?.admin_discount?.toLocaleString()}{" "}
                              (IQD)
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            color: "success.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          المبلغ الكلي:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          sx={{
                            color: "success.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder.data?.net_amount?.toLocaleString()} (IQD)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "info.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          المبلغ المدفوع:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "info.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder.data?.total_paid?.toLocaleString()} (IQD)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          المبلغ المتبقي:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          {_JOrder.data?.remaining?.toLocaleString()} (IQD)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} display="flex" justifyContent="end">
                    <Box>
                      <Typography fontWeight="bold" py={1}>
                        <i></i> للتواصل مع الادارة :
                      </Typography>
                      <Typography fontSize="17px" fontWeight="bold">
                        <i></i> 07850000037
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default OrderPrintDialog;
