import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "../notificationSlice";
import { factory } from "../../../api/apiFactory";
import dayjs from "dayjs";
const jMonthlyTargetApi = factory.get("jMonthlyTarget");
const initialState = {
  searchBody: {
    skip: 0,
    take: 15,
    year: dayjs().format("YYYY"),
    month: dayjs().format("MM"),
    user_id: null,
    j_item_id: null,
    is_achieved: null,
  },
  jMonthlyTarget: {
    loading: false,
    dialog: false,
    active: false,
    form: {
      id: null,
      year: null,
      month: null,
      j_item_id: null,
      user_id: null,
      target: "",
    },
  },
  jMonthlyTargetData: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
};

export const JMonthlyTargetSlice = createSlice({
  name: "jMonthlyTarget",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.jMonthlyTarget.dialog = !state.jMonthlyTarget.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.jMonthlyTargetData.data = payload.data.data;
      state.jMonthlyTargetData.total = payload.data.total;
      state.jMonthlyTargetData.total_amount = payload.data.total_amount;
    },
    setById: (state, { payload }) => {
      state.jMonthlyTarget.form = payload;
    },
    setLoading: (state, action) => {
      state.jMonthlyTargetData.loading = !state.jMonthlyTargetData.loading;
    },
    resetForm: (state, action) => {
      state.jMonthlyTarget.form = initialState.jMonthlyTarget.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
} = JMonthlyTargetSlice.actions;

export default JMonthlyTargetSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jMonthlyTarget"));
    const res = await jMonthlyTargetApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("jMonthlyTarget"));
  } catch (err) {
    dispatch(setLoading("jMonthlyTarget"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await jMonthlyTargetApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (data, forUpdate) => async (dispatch) => {
  try {
    await dispatch(setById(data));
    dispatch(setDialog());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    const res = await jMonthlyTargetApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    const res = await jMonthlyTargetApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم تعديل المنتج بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JMonthlyTargetActions = {
  getAll,
  deleteById,
  getById,
  create,
  update,
};
