import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  LinearProgress,
  InputAdornment,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  JInvoice,
  resetForm,
  setDialog,
} from "../../../../app/slices/jomla/invoiceSlice";
import { SelectJSupplier } from "../../../../components/Selectors/jomla/SelectJSupplier";
import { NumericFormat } from "react-number-format";
import { SelectJItem } from "../../../../components/Selectors/jomla/SelectJItem";
import { BiTrash } from "react-icons/bi";
import { toNumber } from "../../../../helpers/toNumber";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { showNotification } from "../../../../app/slices/notificationSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JInvoiceForm = (props) => {
  const dispatch = useDispatch();

  const _JInvoice = useSelector((state) => state.jInvoice.jInvoice);
  const _SearchBody = useSelector((state) => state.jInvoice.searchBody);
  const _JSupplier = useSelector(
    (state) => state.jSupplier.jSuppliersList.data
  );
  const _JItem = useSelector((state) => state.JItems.JItemsList.data);

  const [loading, setLoading] = useState(false);

  const isAdd = _JInvoice.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: _JInvoice.form,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "j_invoice_item",
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (isAdd) {
      dispatch(
        JInvoice.create(
          {
            j_supplier_id: data.j_supplier_id,
            amount: parseInt(data.amount),
            discount: parseInt(data.discount),
            net_amount: parseInt(data.net_amount),
            cash_paid: 0,
            j_invoice_item: data.j_invoice_item,
          },
          _SearchBody
        )
      )
        .then((x) => setLoading(false))
        .catch((e) => setLoading(false));
    } else {
      dispatch(
        JInvoice.update(
          data.id,
          {
            j_supplier_id: data.j_supplier_id,
            amount: parseInt(data.amount),
            discount: parseInt(data.discount),
            net_amount: parseInt(data.net_amount),
            cash_paid: 0,
            j_invoice_item: data.j_invoice_item,
          },
          _SearchBody
        )
      )
        .then(() => setLoading(false))
        .catch((e) => setLoading(false));
    }
  };

  useEffect(() => {
    reset({ ..._JInvoice.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_JInvoice.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  useEffect(() => {
    if (props.selectedItem) {
      setValue(`j_invoice_item[${0}].j_item_id`, props.selectedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedItem]);

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: parseInt(values.value),
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_JInvoice.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="xl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : props.readOnly ? "فاتورة الشراء" : "تعديل "}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {_JInvoice.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            {isAdd ? (
              <Grid item xs={12} md={12}>
                <Controller
                  name="j_supplier_id"
                  control={control}
                  render={({ field }) => (
                    <SelectJSupplier
                      {...field}
                      required
                      onChange={(e, newValue) => {
                        setValue(
                          "j_supplier_id",
                          newValue ? newValue.id : null
                        );
                      }}
                      value={
                        _JSupplier.filter((x) => x.id === field.value)[0] ??
                        null
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={6}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"discount"}
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onChange={(e) => {
                      const amount = watch("amount");
                      const discount = toNumber(e.target.value);
                      const net_amount = amount - discount;
                      setValue("net_amount", net_amount);
                      setValue("discount", toNumber(e.target.value));
                    }}
                    valueIsNumericString={true}
                    allowNegative={false}
                    fullWidth
                    label="الخصم"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="net_amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ الصافي"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {/* {isAdd ? (
              <Grid item xs={12} md={6}>
                <Controller
                  name="cash_paid"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="المبلغ المدفوع"
                      fullWidth
                      {...field}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null} */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table>
                    <TableBody>
                      {fields.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell sx={{ width: "1%" }}>
                            {`${index + 1}- `}
                          </TableCell>
                          <TableCell sx={{ width: "40%" }}>
                            <Controller
                              control={control}
                              name={`j_invoice_item[${index}].j_item_id`}
                              defaultValue={item.j_item_id}
                              render={({ field }) => (
                                <SelectJItem
                                  {...field}
                                  required
                                  onChange={(e, newValue) => {
                                    if (newValue?.is_no_stock)
                                      dispatch(
                                        showNotification({
                                          message:
                                            "العنصر محدد كقيمة لا مخزنية",
                                          type: "error",
                                        })
                                      );
                                    else {
                                      setValue(
                                        `j_invoice_item[${index}].j_item_id`,
                                        newValue ? newValue.id : null
                                      );
                                      setValue(
                                        `j_invoice_item[${index}].price`,
                                        newValue
                                          ? newValue.last_buying_price
                                          : 0
                                      );
                                      const totalAmount = watch(
                                        "j_invoice_item"
                                      ).reduce(
                                        (sum, item) =>
                                          sum + (item.price * item.count || 0),
                                        0
                                      );
                                      setValue("amount", totalAmount);
                                      const netAmount =
                                        totalAmount - watch("discount");
                                      setValue("net_amount", netAmount);
                                    }
                                  }}
                                  value={
                                    _JItem.filter(
                                      (x) => x.id === field.value
                                    )[0] ?? null
                                  }
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "20%" }}>
                            <Controller
                              name={`j_invoice_item[${index}].price`}
                              control={control}
                              defaultValue={item.price}
                              render={({ field }) => (
                                <NumericFormat
                                  thousandSeparator
                                  customInput={TextField}
                                  value={field.value}
                                  onChange={(e) => {
                                    setValue(
                                      `j_invoice_item[${index}].price`,
                                      toNumber(e.target.value)
                                    );
                                    setValue(
                                      `j_invoice_item[${index}].totalPrice`,
                                      toNumber(e.target.value) *
                                      watch(`j_invoice_item[${index}].count`)
                                    );
                                    const amount = watch(
                                      "j_invoice_item"
                                    ).reduce(
                                      (total, item) =>
                                        total + item.price * item.count,
                                      0
                                    );
                                    setValue("amount", amount);
                                    const netAmount =
                                      amount - watch("discount");
                                    setValue("net_amount", netAmount);
                                  }}
                                  valueIsNumericString={true}
                                  allowNegative={false}
                                  fullWidth
                                  label="السعر"
                                  size="small"
                                // helperText={`سعر اخر شراء: ${watch(
                                //   `j_invoice_item[${index}].price`
                                // )}`}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "10%" }}>
                            <Controller
                              control={control}
                              name={`j_invoice_item[${index}].count`}
                              defaultValue={item.count}
                              render={({ field }) => (
                                <NumericFormat
                                  thousandSeparator
                                  customInput={TextField}
                                  value={field.value}
                                  onChange={(e) => {
                                    setValue(
                                      `j_invoice_item[${index}].count`,
                                      toNumber(e.target.value)
                                    );
                                    setValue(
                                      `j_invoice_item[${index}].totalPrice`,
                                      toNumber(e.target.value) *
                                      watch(`j_invoice_item[${index}].price`)
                                    );

                                    const totalAmount = watch(
                                      "j_invoice_item"
                                    ).reduce(
                                      (sum, item) =>
                                        sum + (item.price * item.count || 0),
                                      0
                                    );
                                    setValue("amount", totalAmount);
                                    const netAmount =
                                      totalAmount - watch("discount");
                                    setValue("net_amount", netAmount);
                                  }}
                                  valueIsNumericString={true}
                                  allowNegative={false}
                                  fullWidth
                                  label="العدد"
                                  size="small"
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "15%" }}>
                            <Controller
                              control={control}
                              name={`j_invoice_item[${index}].totalPrice`}
                              defaultValue={item.count * item.price}
                              render={({ field }) => (
                                <TextField
                                  required
                                  size="small"
                                  label="السعر الكلي"
                                  fullWidth
                                  disabled
                                  {...field}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    startAdornment: (
                                      <InputAdornment position="start"></InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "15%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Controller
                                name={`j_invoice_item[${index}].expiry_date`}
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    inputFormat="YYYY-MM-DD"
                                    label="تاريخ الانتهاء"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                      />
                                    )}
                                    {...field}
                                    onChange={(newValue) => {
                                      field.onChange(
                                        dayjs(newValue).format("YYYY-MM-DD")
                                      );
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell sx={{ width: "1%" }}>
                            <Tooltip title="حذف الصف">
                              {props.readOnly ? null : (
                                <IconButton
                                  color="error"
                                  onClick={() => {
                                    const removedValue =
                                      watch(`j_invoice_item[${index}].price`) *
                                      watch(`j_invoice_item[${index}].count`);
                                    setValue(
                                      "amount",
                                      getValues("amount") - removedValue
                                    );
                                    setValue(
                                      "net_amount",
                                      getValues("net_amount") - removedValue
                                    );
                                    remove(index);
                                  }}
                                >
                                  <BiTrash />
                                </IconButton>
                              )}
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  {props.readOnly ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        append({
                          j_item_id: "",
                          count: "",
                          price: "",
                          expiry_date: null,
                        })
                      }
                    >
                      اضافة منتج جديد
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {props.readOnly ? null : (
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading ? true : false}
                  endIcon={loading ? <CircularProgress size={25} /> : null}
                >
                  حفظ
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JInvoiceForm;
