import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, CircularProgress } from "@mui/material";
import { useEffect, forwardRef, Fragment } from "react";
import { styled } from "@mui/system";
import { Product } from "../../app/slices/productsSlice";

export const SelectProducts = forwardRef((props, ref) => {
  const _Products = useSelector((state) => state.product.products);
  const reload = useSelector((state) => state.product.reload);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Product.getAll({ take: 500 }));
  }, []);

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    borderBottom: "1px solid #e0e0e0",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <Autocomplete
      ref={ref}
      options={_Products.data || []}
      {...props}
      getOptionLabel={(option) =>
        `${option.name} - ${option.total_price?.toLocaleString()} IQD`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          required={props.required}
          error={props.error}
          helperText={props.helperText}
          {...params}
          label="المنتجات"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {reload ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderGroup={(params, key) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
});
