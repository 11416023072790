import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Chip,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Slide,
  Autocomplete,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdHistory, MdOutlineClear, MdRemoveRedEye } from "react-icons/md";
import {
  JItems,
  setJItemStatusDialog,
  setListSearchBody,
  setSearchBody,
} from "../../../../../app/slices/jomla/itemsSlice";
import { SelectJSubCategory } from "../../../../../components/Selectors/jomla/SelectJSubCategory";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { SelectJGroup } from "../../../../../components/Selectors/jomla/SelectJGroup";
import { GiTakeMyMoney } from "react-icons/gi";
import { setDialog } from "../../../../../app/slices/jomla/invoiceSlice";
import JInvoiceForm from "../../../Invoice/form";
import useDebounce from "../../../../../components/hooks/useDebounce";
import { Image, Visibility, VisibilityOff } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JItemsTable = () => {
  const dispatch = useDispatch();

  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);
  const _JItems = useSelector((state) => state.JItems.JItems);
  const _IsNoStock = useSelector((state) => state.JItems.isNoStock);
  const _SearchBody = useSelector((state) => state.JItems.searchBody);
  const _JSubCategory = useSelector(
    (state) => state.JSubCategories.JSubCategoriesList.data
  );
  const _Group = useSelector((state) => state.jGroup.jGroupList);
  const JInvoice = useSelector((state) => state.jInvoice);
  const _ListSearchBody = useSelector(
    (state) => state.JItems.JItemsList.searchBody
  );

  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [dialogActiveData, setDialogActiveData] = useState({});
  const [openActive, setOpenActive] = useState(false);
  const [openCarouselDialog, setOpenCarouselDialog] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_JItems.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JItems.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(JItems.active(dialogActiveData.id, _SearchBody)).then((x) => {});
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
  };

  const columns = [
    {
      field: "code",
      headerName: "Code",
      flex: 0.6,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.name} followCursor>
          <Typography variant="body" noWrap>
            {params.row.name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "price",
      headerName: "السعر (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.price).toLocaleString(),
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 1,
      renderCell: (params) => parseInt(params.row.count).toLocaleString(),
    },
    {
      field: "discount",
      headerName: "الخصم (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.discount).toLocaleString(),
    },
    {
      field: "total_price",
      headerName: "السعر الكلي (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.total_price).toLocaleString(),
    },
    {
      field: "sort_no",
      headerName: "تسلسل",
      flex: 0.6,
    },
    {
      field: "j_sub_category_name",
      headerName: "القسم الفرعي",
      flex: 1,
    },
    {
      field: "is_no_stock",
      headerName: "لا مخزنية",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_no_stock ? "نعم" : "كلا"}
            color={params.row.is_no_stock ? "success" : "default"}
          ></Chip>
        );
      },
    },
    {
      field: "last_buying_price",
      headerName: "اخر سعر شراء (IQD)",
      flex: 1,
      renderCell: (params) => params.row.last_buying_price?.toLocaleString(),
    },
    // {
    //   field: "is_hidden",
    //   headerName: "اخفاء المنتج !",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Chip
    //         label={params.row.is_hidden ? "مخفي" : "مرئي"}
    //         color={params.row.is_hidden ? "default" : "success"}
    //       ></Chip>
    //     );
    //   },
    // },
    {
      field: "is_active",
      headerName: "حالة المنتج",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Chip
              label={params.row.is_active ? "فعال" : "غير فعال"}
              color={params.row.is_active ? "success" : "error"}
              onClick={() => {
                handleActiveClick(params.row);
              }}
            ></Chip>
            {params.row.is_hidden ? <VisibilityOff /> : <Visibility />}
          </Stack>
        );
      },
    },
    {
      field: "attachments",
      headerName: "الصور",
      flex: 0.4,
      renderCell: (params) => (
        <IconButton
          disabled={!params.row.logo?.length}
          onClick={() => {
            setAttachmentsData(params.row.logo);
            setOpenCarouselDialog(true);
          }}
        >
          <Image />
        </IconButton>
      ),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="اضافة فاتورة شراء" followCursor placement="top">
              <IconButton
                onClick={() => {
                  setSelectedItem(params.row.id);
                  dispatch(setListSearchBody({ search: params.row.name }));
                  dispatch(setDialog());
                }}
              >
                <GiTakeMyMoney />
              </IconButton>
            </Tooltip>
            <Tooltip title="حركات المادة" followCursor placement="top">
              <IconButton
                onClick={() => {
                  dispatch(setJItemStatusDialog(params.row.id));
                }}
              >
                <MdHistory />
              </IconButton>
            </Tooltip>
            <IconButton onClick={() => dispatch(JItems.getById(params.row.id))}>
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JItems.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        j_sub_category_id: search.j_sub_category_id ?? "",
        is_no_stock: search.is_no_stock ?? "",
        j_group_id: search.j_group_id ?? "",
        code: search.code,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  const debouncedSearch = useDebounce(_ListSearchBody, 500);

  useEffect(() => {
    dispatch(JItems.getAllList(debouncedSearch));
  }, [debouncedSearch, dispatch]);

  return (
    <Card>
      <CardContent>
        <Box>
          {JInvoice.jInvoice.dialog ? (
            <JInvoiceForm selectedItem={selectedItem} />
          ) : null}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            type="confirm"
            open={openActive}
            title="تاكيد"
            message={
              dialogActiveData.is_active
                ? "هل انت متاكد من الغاء تفعيل :"
                : "هل انت متاكد من تفعيل :"
            }
            name={dialogActiveData.name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
          <Dialog
            open={openCarouselDialog}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth="md"
            onClose={() => {
              setOpenCarouselDialog(false);
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <ConfirmationDialog
              open={openConfirm}
              title="تاكيد"
              message="هل انت متاكد من حذف الصورة"
              name={""}
              confirmText="حذف"
              cancelText="الغاء"
              onConfirm={() => handleConfirmDelete()}
              onCancel={handleCancelDelete}
            />
            <DialogContent>
              <Typography
                mb={3}
                variant="h4"
                color={"purple"}
                gutterBottom
                component="div"
                sx={{ textAlign: "end" }}
              >
                {"الصور"}
              </Typography>
              <Divider />
              <Box display="flex" justifyContent="center" zIndex={999}>
                <Carousel
                  swipeable
                  emulateTouch
                  infiniteLoop
                  width={400}
                  renderThumbs={(children) =>
                    children.map((child, index) => (
                      <img
                        key={index}
                        src={child.props.src}
                        alt=""
                        style={{
                          height: "50px",
                          width: "auto",
                          objectFit: "contain",
                        }}
                      />
                    ))
                  }
                >
                  {attachmentsData?.map((attachment, index) => (
                    <Box
                      key={index}
                      component="img"
                      src={attachment}
                      alt=""
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  ))}
                </Carousel>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.code}
              onChange={(e) => {
                setSearch({
                  ...search,
                  code: e.target.value,
                });
              }}
              label="بحث عن الكود "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectJSubCategory
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_sub_category_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JSubCategory.filter(
                  (x) => x.id === search.j_sub_category_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2.5}>
            <SelectJGroup
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_group_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _Group.filter((x) => x.id === search.j_group_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={_IsNoStock}
              getOptionLabel={(option) => {
                return option.name;
              }}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  is_no_stock: newValue ? newValue.value : null,
                });
              }}
              value={
                _IsNoStock.filter((x) => x.value === search.is_no_stock)[0] ??
                null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"قيمة لا مخزنية"}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              {_Role === "representative" ? null : (
                <Tooltip title="تصدير ملف اكسل">
                  <IconButton
                    onClick={() => {
                      dispatch(JItems.exportExcel(_SearchBody));
                    }}
                  >
                    <BsFillFileEarmarkExcelFill />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      search: "",
                      j_sub_category_id: null,
                      group_id: null,
                      province_id: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              {_JItems.data ? (
                <DataGrid
                  rows={_JItems.data.map((x, i) => {
                    return {
                      ...x,
                      index: i + 1 + pageSize * currentPage,
                    };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_JItems.total}
                  loading={_JItems.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: CustomFooter,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JItemsTable;
