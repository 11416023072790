import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./../notificationSlice";
import { factory } from "../../../api/apiFactory";
import dayjs from "dayjs";
const JItemsApi = factory.get("JItems");
const initialState = {
  searchBody: {
    search: "",
    code: "",
    j_sub_category_id: null,
    is_no_stock: null,
    skip: 0,
    take: 15,
  },
  JItem: {
    loading: false,
    dialog: false,
    active: false,
    form: {
      id: "",
      j_sub_category_id: null,
      j_sub_category_logo: "",
      j_sub_category_name: "",
      name: "",
      description: "",
      price: "",
      discount: 0,
      attachments: "",
      is_hidden: false,
      is_active: true,
      is_no_stock: false,
      sort_no: 0,
      j_item_group: [],
    },
  },
  JItems: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  JItemsList: {
    loading: false,
    searchBody: {},
    data: [],
    total: 0,
  },
  isNoStock: [
    { name: "قيمة مخزنية", value: "0" },
    { name: "قيمة لا مخزنية", value: "1" },
  ],
  jItemHistory: {
    loading: false,
    dialog: false,
    itemId: null,
    data: [],
    total: 0,
    total_amount: 0,
  },
  jItemHistorySearchBody: {
    start_date: dayjs().format("2024-01-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  },
};

export const JItemsSlice = createSlice({
  name: "JItems",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.JItem.dialog = !state.JItem.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.JItems.data = payload.data.data;
      state.JItems.total = payload.data.total;
      state.JItems.total_amount = payload.data.total_amount;
    },
    setDataList: (state, { payload }) => {
      state.JItemsList.data = [
        ...new Set(
          [...state.JItemsList.data, ...payload.data.data].map((item) =>
            JSON.stringify(item)
          )
        ),
      ].map((item) => JSON.parse(item));
    },
    setById: (state, { payload }) => {
      state.JItem.form.id = payload.id;
      state.JItem.form.count = payload.count;
      state.JItem.form.description = payload.description;
      state.JItem.form.discount = payload.discount
        ? parseInt(payload.discount)
        : 0;
      state.JItem.form.price = parseInt(payload.price);
      state.JItem.form.is_active = payload.is_active;
      state.JItem.form.is_hidden = payload.is_hidden;
      state.JItem.form.is_no_stock = payload.is_no_stock;
      state.JItem.form.j_sub_category_id = payload.j_sub_category_id;
      state.JItem.form.j_sub_category_logo = payload.j_sub_category_logo;
      state.JItem.form.j_sub_category_name = payload.j_sub_category_name;
      state.JItem.form.attachments = payload.logo;
      state.JItem.form.name = payload.name;
      state.JItem.form.sort_no = payload.sort_no;
      state.JItem.form.j_item_group = payload.j_item_group;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.JItem.form = initialState.JItem.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setListSearchBody: (state, { payload }) => {
      state.JItemsList.searchBody = payload;
    },
    setJItemStatusDialog: (state, { payload }) => {
      state.jItemHistory.dialog = !state.jItemHistory.dialog;
      if (payload) {
        state.jItemHistory.itemId = payload;
      } else {
        state.jItemHistory.itemId = null;
      }
    },
    setJItemStatusDataTable: (state, { payload }) => {
      state.jItemHistory.data = payload.data.data;
      state.jItemHistory.total = payload.data.total;
      state.jItemHistory.total_amount = payload.data.total_amount;
    },
    setJItemStatusListSearchBody: (state, { payload }) => {
      state.jItemHistorySearchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
  setListSearchBody,
  setJItemStatusDialog,
  setJItemStatusDataTable,
  setJItemStatusListSearchBody,
} = JItemsSlice.actions;

export default JItemsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("JItems"));
    const res = await JItemsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("JItems"));
  } catch (err) {
    dispatch(setLoading("JItems"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("JItems"));
    const res = await JItemsApi.getAllList(params);
    dispatch(setDataList(res));
    dispatch(setLoading("JItems"));
  } catch (err) {
    dispatch(setLoading("JItems"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await JItemsApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("JItems"));
    const res = await JItemsApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("JItems"));
  } catch (err) {
    dispatch(setLoading("JItems"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await JItemsApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog(_SearchBody));
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await JItemsApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم تعديل المنتج بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const active = (id, _SearchBody) => async (dispatch) => {
  try {
    await JItemsApi.active(id);
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const deleteAttachment = (id, _SearchBody) => async (dispatch) => {
  try {
    const res = await JItemsApi.deleteAttachment(id);
    dispatch(
      showNotification({
        message: "تم حذف الصورة بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("JItems"));
    const res = await JItemsApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `jomla-items.xlsx`;
    a.click();
    dispatch(setLoading("JItems"));
  } catch (err) {
    dispatch(setLoading("JItems"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getJItemHistory = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jItemHistory"));
    const res = await JItemsApi.getJItemHistory(params);
    dispatch(setJItemStatusDataTable(res));
    dispatch(setLoading("jItemHistory"));
  } catch (err) {
    dispatch(setLoading("jItemHistory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JItems = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  active,
  getAllList,
  deleteAttachment,
  exportExcel,
  getJItemHistory,
};
