import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const pinsApi = factory.get("pins");
const orderApi = factory.get("orders");
const initialState = {
  searchBody: {
    expiry_date: "",
    operation_id: "",
    pin_number: "",
    item_id: "",
    status: "",
    serial_number: "",
    skip: 0,
    take: 15,
    page: 0,
  },
  pin: {
    loading: false,
    dialog: false,
    excelDialog: false,
    csvDialog: false,
    selectedItem: null,
    form: {
      id: "",
      serial_number: "",
      pin_number: "",
      expiry_date: dayjs(new Date()).format("YYYY-MM-DD"),
      buying_price: "",
      item_id: null,
      status: null,
      supplier_id: null,
      cash_paid: "",
    },
    excelForm: {
      id: null,
      buying_price: "",
      item_id: null,
      upload_file: null,
      supplier_id: null,
      note: "",
      is_private: false,
    },
  },
  pins: {
    loading: false,
    data: [],
    total: 0,
  },
  checkExcelError: {},
  updateExcelPrice: {
    dialog: false,
    operation_id: null,
  },
  orderDetails: {
    dialog: false,
    order_id: null,
    data: [],
    total: 0,
  },
};

export const pinsSlice = createSlice({
  name: "pins",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.pin.dialog = !state.pin.dialog;
    },
    setExcelDialog: (state, action) => {
      state.pin.excelDialog = !state.pin.excelDialog;
    },
    setCsvDialog: (state, action) => {
      state.pin.csvDialog = !state.pin.csvDialog;
    },
    setUpdateExcelDialog: (state, { payload }) => {
      state.updateExcelPrice.dialog = !state.updateExcelPrice.dialog;
      state.updateExcelPrice.operation_id = payload;
    },
    setorderDetailsDialog: (state, { payload }) => {
      state.orderDetails.dialog = !state.orderDetails.dialog;
      state.orderDetails.order_id = payload;
    },
    setOrderDetailsDataTable: (state, { payload }) => {
      state.orderDetails.data = payload.data.data[0];
    },
    setDataTable: (state, { payload }) => {
      state.pins.data = payload.data.data;
      state.pins.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.pin.form.id = payload.id;
      state.pin.form.serial_number = payload.serial_number;
      state.pin.form.pin_number = payload.pin_number;
      state.pin.form.expiry_date = payload.expiry_date;
      state.pin.form.buying_price = payload.buying_price;
      state.pin.form.item_id = payload.item;
      state.pin.form.status = payload.status;
      state.pin.form.supplier_id = payload.supplier;
    },
    setSelectedItem: (state, { payload }) => {
      state.pin.selectedItem = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.pin.form = initialState.pin.form;
    },
    resetExcelForm: (state, action) => {
      state.pin.excelForm = initialState.pin.excelForm;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setCheckExcelError: (state, { payload }) => {
      state.checkExcelError = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setSelectedItem,
  setExcelDialog,
  setCsvDialog,
  setById,
  setDataTable,
  resetForm,
  resetExcelForm,
  setSearchBody,
  setCheckExcelError,
  setUpdateExcelDialog,
  setorderDetailsDialog,
  setOrderDetailsDataTable,
} = pinsSlice.actions;

export default pinsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("pins"));
    const res = await pinsApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("pins"));
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await pinsApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("pins"));
    const res = await pinsApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("pins"));
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await pinsApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await pinsApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم تعديل المنتج بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const importExcelFile = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("pins"));
    await pinsApi.importExcelFile(id, data);
    dispatch(
      showNotification({
        message: "تم الرفع بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("pins"));
    dispatch(setExcelDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const importCsvFile = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("pins"));
    await pinsApi.importCsvFile(id, data);
    dispatch(
      showNotification({
        message: "تم الرفع بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("pins"));
    dispatch(setCsvDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const checkExcelFile = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("pins"));
    await pinsApi.checkExcelFile(id, data);
    dispatch(
      showNotification({
        message: "تم الرفع بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("pins"));
    dispatch(setExcelDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setCheckExcelError(err.response.data.errors));
    throw new Error(err);
  }
};
const replacePin = (data) => async (dispatch) => {
  try {
    await pinsApi.replacePin(data);
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("pins"));
    dispatch(getAll());
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const updateExcelPrice = (data) => async (dispatch) => {
  try {
    await pinsApi.updateExcelPrice(data);
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("pins"));
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("pins"));
    const res = await orderApi.getById(id);
    dispatch(setOrderDetailsDataTable(res));
    dispatch(setLoading("pins"));
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("pins"));
    const res = await pinsApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `pins.xlsx`;
    a.click();
    dispatch(setLoading("pins"));
  } catch (err) {
    dispatch(setLoading("pins"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Pins = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  importExcelFile,
  importCsvFile,
  replacePin,
  checkExcelFile,
  updateExcelPrice,
  getOrderDetails,
  exportExcel,
};
