import {
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JReports } from "../../../../../../app/slices/jomla/reportsSlice";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import { JItems } from "../../../../../../app/slices/jomla/itemsSlice";
import { SelectJRep } from "../../../../../../components/Selectors/SelectJRep";

const JPosDebt = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    j_rep_id: null,
  });

  const _JPosDebt = useSelector((state) => state.jReports.jPosDebt);
  const loading = useSelector((state) => state.jReports.loading);
  const reportTypes = useSelector((state) => state.jReports.reportTypes);
  const user = useSelector((state) => state.user.jRepList.data);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "pos_name",
      headerName: "اسم النقطة",
      flex: 1,
    },
    {
      field: "pos_number",
      headerName: "رقم النقطة",
      flex: 1,
    },
    {
      field: "address",
      headerName: "العنوان",
      flex: 1,
    },
    {
      field: "j_rep_name",
      headerName: "المندوب",
      flex: 1,
    },
    {
      field: "total_amount",
      headerName: "المجموع الكلي",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>{params.row.total_amount?.toLocaleString()}</Typography>
        );
      },
    },
    {
      field: "total_count",
      headerName: "العدد الطلبات",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>{params.row.total_count?.toLocaleString()}</Typography>
        );
      },
    },
    {
      field: "total_remaining",
      headerName: "الديون",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.total_remaining?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "last_order",
      headerName: "اخر طلب",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography variant="caption">
              {params.row.last_j_order_value?.toLocaleString()}
            </Typography>
            <Typography>
              {dayjs(params.row.last_j_order_date).format("YYYY-MM-DD HH:mm")}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "last_transaction",
      headerName: "اخر تسديد",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>
              {params.row.last_payment_value?.toLocaleString()}
            </Typography>
            <Typography variant="caption">
              {params.row.last_payment_date
                ? dayjs(params.row.last_payment_date).format("YYYY-MM-DD HH:mm")
                : ""}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      JReports.jPosDebt({
        start_date: search.startDate,
        end_date: search.endDate,
        j_rep_id: search.j_rep_id,
      })
    );
  }, [dispatch, reportTypes, search]);

  useEffect(() => {
    dispatch(JItems.getAllList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "حركات البيع | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية للديون : {_JPosDebt.total_amount?.toLocaleString()}{" "}
          (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectJRep
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_rep_id: newValue ? newValue.id : null,
                });
              }}
              value={
                user.filter((user) => user.id === search.j_rep_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    startDate: dayjs().format("YYYY-MM-01"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                    j_rep_id: null,
                  });
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "80vh", width: "100%" }}>
              <DataGrid
                rows={
                  _JPosDebt.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                    id: index + 1,
                  })) ?? []
                }
                loading={loading}
                columns={columns}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JPosDebt;
