import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { GiExpense } from "react-icons/gi";
import { useSelector } from "react-redux";
import { setOpenDialog } from "../../../../../app/slices/expenseTypesSlice";
import { IoIosAddCircleOutline } from "react-icons/io";
import JExpenseForm from "../../from";
import ExpenseTypes from "../../../../expense/expenseTypes";
import { setDialog } from "../../../../../app/slices/jomla/expenseSlice";

const JExpenseHeader = () => {
  const JExpense = useSelector((state) => state.jExpense);
  const _ExpenseTypes = useSelector((state) => state.expenseTypes);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المصاريف
          </Typography>
        }
        avatar={<GiExpense size={20} />}
        action={
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setOpenDialog())}
            >
              أنواع المصاريف
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة
            </Button>
          </Stack>
        }
      />
      {JExpense.jExpense.dialog ? <JExpenseForm /> : null}
      {_ExpenseTypes.dialog ? <ExpenseTypes /> : null}
    </Card>
  );
};

export default JExpenseHeader;
