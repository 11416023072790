import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { FaRegCalendarAlt } from "react-icons/fa";
import { setDialog } from "../../../../../app/slices/jomla/monthlyTargetSlice";
import JMonthlyTargetForm from "../../from";

const JMonthlyTargetHeader = () => {
  const dispatch = useDispatch();

  const _JMonthlyTarget = useSelector(
    (state) => state.jMonthlyTarget.jMonthlyTarget
  );

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الهدف الشهري للمندوبين
          </Typography>
        }
        avatar={<FaRegCalendarAlt size={20} />}
        action={
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة
            </Button>
          </Stack>
        }
      />
      {_JMonthlyTarget.dialog ? <JMonthlyTargetForm /> : null}
    </Card>
  );
};

export default JMonthlyTargetHeader;
